import React, { useState, useEffect } from 'react';
import NewTodo from './components/check';
import styles from "./styles/newtodo.module.css";
import { TodoistApi } from "@doist/todoist-api-typescript";
import { Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import TodoTable from './components/table';


const NewTodoMain: React.FC<any> = (props: any) => {

    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [projects, setProjects] = useState<any>([]);
    const [section, setSection] = useState<any>([]);
    const [users, setUsers] = useState<any>([]);
    const [tasks, setTasks] = useState<any>([]);
    const [menu, setMenu] = useState<any>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [update, setUpdate] = useState<number>(0);

    const [projectsData, setProjectsData] = useState<any>([]);
    const [sectionsData, setSectionsData] = useState<any>([]);
    const [collaboratorsData, setCollaboratorsData] = useState<any>([]);
    const [tasksData, setTasksData] = useState<any>([]);

    useEffect(() => {

        props.activateMenuList("circle-check");

    }, []);

    useEffect(() => {

        //하나라도 없으면 return
        if(projectsData.length === 0 || sectionsData.length === 0 || collaboratorsData.length === 0 || tasksData.length === 0) {
            return;
        }

        makeFinalProjects(projectsData, sectionsData, tasksData, collaboratorsData);

    }, [projectsData, sectionsData, collaboratorsData, tasksData]);

    useEffect(() => {

        if (!props.user) return;

        if(!props.user.todoistApiKey){
            alert("관리자 전용 페이지 입니다");
            return;
        }

        getProjects(props.user.todoistApiKey);

    }, [props.user, update, menu]);


    const getProjects = async (token : string) => {

        try {

            if (update === 0) {
                setLoading(true);
            }

            setProjectsData([]);
            setSectionsData([]);
            setCollaboratorsData([]);
            setTasksData([]);

            console.log("프로젝트 데이터를 불러옵니다.");

            // Promise.all로 병렬 요청
            const [projectsRes, sectionsRes, collaboratorsRes, tasksRes] = await Promise.all([
                fetch("https://api.todoist.com/rest/v2/projects", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
                fetch("https://api.todoist.com/rest/v2/sections", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
                fetch("https://api.todoist.com/rest/v2/projects/6XGm6MQ5pxhH3cg2/collaborators", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
                fetch("https://api.todoist.com/rest/v2/tasks", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            ]);

            // 응답을 JSON으로 파싱도 Promise.all로 병렬 처리
            const [preProjectsData, sectionsData, collaboratorsData, tasksData] = await Promise.all([
                projectsRes.json(),
                sectionsRes.json(),
                collaboratorsRes.json(),
                tasksRes.json(),
            ]);

            //projectData에서 index 0은 제외
            const projectsData = preProjectsData.filter((project: any) => {
                return !project.is_inbox_project && project.is_shared;
            });

            setProjectsData(projectsData);
            setSectionsData(sectionsData);
            setCollaboratorsData(collaboratorsData);
            setTasksData(tasksData);

            //makeFinalProjects(projectsData, sectionsData, tasksData, collaboratorsData);

        } catch (error) {
            console.error("데이터를 불러오는 중 오류가 발생했습니다:", error);
        }

    }

    const makeFinalProjects = (projectsData : any, sectionsData : any, tasksData : any, collaboratorsData : any) => {
        projectsData.forEach((project: any) => {

            project.sections = [{
                id : project.id + "sectionwithout",
                name : "미배정",
                order : 0,
                tasks : []
            }];

            sectionsData.forEach((section: any) => {

                section.tasks = [];

                if (section.project_id === project.id) {
                    project.sections.push(section);
                }
            });

        });

        const newTasks : any = [];

        tasksData.forEach((task: any) => {
            collaboratorsData.forEach((collaborator: any) => {
                if(task.assiner_id && task.assiner_id === collaborator.id) {
                    task.assiner = collaborator;
                    task.assinerName = collaborator.name;
                }
                if(task.assinee_id && task.assinee_id === collaborator.id) {
                    task.assinee = collaborator;
                    task.assineeName = collaborator.name;
                }
                if(task.creator_id && task.creator_id === collaborator.id) {
                    task.creator = collaborator;
                    task.creatorName = collaborator.name;
                }
            });
        });

        tasksData.forEach((task: any) => {

            if(task.parent_id){
                return;
            }

            task.subTasks = [];

            tasksData.forEach((subTask: any) => {

                if(!subTask.parent_id) {
                    return;
                }

                if(subTask.parent_id === task.id) {
                    task.subTasks.push(subTask);
                }
            });

            newTasks.push(task);

        });

        newTasks.forEach((task: any) => {

            const selectedProject = projectsData.find((project: any) => {
                return project.id === task.project_id;
            });

            if(!selectedProject) {
                return;
            }

            if(!task.section_id) {
                selectedProject.sections[0].tasks.push(task);
            }else{
                const selectedSection = selectedProject.sections.find((section: any) => {
                    return section.id === task.section_id;
                });

                if(!selectedSection) {
                    return;
                }

                selectedSection.tasks.push(task);
            }


        });

        makeProjectCustomOrder(projectsData);

        projectsData.sort((a: any, b: any) => {
            return a.customOrder - b.customOrder;
        });

        projectsData.forEach((project: any) => {
            project.sections.sort((a: any, b: any) => {
                return a.order - b.order;
            });
        });

        projectsData.forEach((project: any) => {
            project.sections.forEach((section: any) => {
                section.tasks.sort((a: any, b: any) => {
                    return a.order - b.order;
                });
            });
        });

        projectsData.forEach((project: any) => {
            project.sections.forEach((section: any) => {
                section.tasks.forEach((task: any) => {
                    task.subTasks.sort((a: any, b: any) => {
                        return a.order - b.order;
                    });
                });
            });
        });

        setProjects([...projectsData]);

        console.log(projectsData);

        setLoading(false);
    }

    const makeProjectCustomOrder = (projects : any) => {

        projects.forEach((project : any, index : number) => {

            project.customOrder = 999;

            if(project.name.includes("행정팀")){
                project.customOrder = 1;
            }

            if(project.name.includes("가을")){
                project.customOrder = 2;
            }

            if(project.name.includes("윤주")){
                project.customOrder = 3;
            }

            if(project.name.includes("수아")){
                project.customOrder = 5;
            }

            if(project.name.includes("보경")){
                project.customOrder = 6;
            }

            if(project.name.includes("방화벽")){
                project.customOrder = 7;
            }

            if(project.name.includes("시설")){
                project.customOrder = 8;
            }

            if(project.name.includes("도훈")){
                project.customOrder = 9;
            }

            if(project.name.includes("기태")){
                project.customOrder = 10;
            }

            if(project.name.includes("코딩")){
                project.customOrder = 11;
            }

            if(project.name.includes("용범")){
                project.customOrder = 12;
            }

            if(project.name.includes("아이디어")){
                project.customOrder = 13;
            }


        });

    }

    const letsUpdate = () => {
        setUpdate(Math.random());
    }

    const taskSectionChanged = (taskId : any, sectionId : string) => {
    
        const newTaskData = tasksData;

        tasksData.forEach((task: any) => {
            if(task.id === taskId) {

                console.log(task);

                task.section_id = sectionId;

                console.log(task);
            }
        });

        setTasksData([...newTaskData]);

    }

    return (
        <div className={styles.main}>

            <Tabs aria-label="Basic tabs" defaultValue={0} sx={{
                width: "1750px",
                backgroundColor: "transparent"
            }}
                onChange={(e: any, value: any) =>
                    setMenu(value)
                }
            >
                <TabList
                >
                    <Tab>표로 보기</Tab>
                    <Tab>페이지 보기</Tab>
                </TabList>
            </Tabs>
            
            {
                menu === 1 ?
                <NewTodo projects={projects} menu={menu} />
                :
                ""
            }

            {
                menu === 0 ?
                <TodoTable projects={projects}
                loading={loading}
                apiKey={props.user ? props.user.todoistApiKey : ""}
                letsUpdate={letsUpdate}
                taskSectionChanged={taskSectionChanged}
                />
                :
                ""
            }

        </div>
    )
}

export default NewTodoMain;