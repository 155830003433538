import React, { useEffect, useState } from "react";
import styles from "./styles/regularmealstatus.module.css";
import { getFetch } from "../../../../fetch/fetch";
import { koreanLocationToEnglish } from "../../../../data/locationFuntion";
import RegularMealTable from "./component/regularmealtable";



const RegularMealStatus: React.FC<any> = (props) => {

    

    return (
        <div>
            <RegularMealTable />
        </div>
    )
}

export default RegularMealStatus;