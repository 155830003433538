import React, { useEffect, useState } from "react";
import InnerHeader from "../etc/innerheader";
import styles from "./mobilerestaurantstatus.module.css";
import { useLocation } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import {Clipboard} from "@capacitor/clipboard";

import {ReactComponent as Paste} from "../../../src/svg/paste-light.svg";
import { useIonAlert } from '@ionic/react';
import { returnAccountInfo } from "../../data/locationFuntion";
import { oneMealTicketPrice } from "../data/datas";


const MobileMealTicketText: React.FC<any> = (props) => {

    const location : any = useLocation();
    const [numbers, setNumbers] = useState<number>(0);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>("");
    const [presentAlert] = useIonAlert();

    useEffect(() => {

        const newNumbers = location.state.numbers;
        const newSelectedPhoneNumber = location.state.selectedPhoneNumber;

        setNumbers(newNumbers);
        setSelectedPhoneNumber(newSelectedPhoneNumber);
        
    }, []);

    return (
        <div>
            <InnerHeader title="충전 신청" />
            <div className={styles.chargeBody}>
                <div className={styles.chargeTitle}>
                    충전 신청이 완료되었습니다
                </div>
                <div className={styles.chargeSubTitle}>
                    신청해주신 식권 금액에 대한<br></br>
                    결제선생 결제 링크가 전송되었습니다.<br></br>
                    결제완료 즉시 식권이 충전됩니다.
                </div>

                <div className={styles.infoBox}>
                    <div className={styles.infoBoxTitle}>
                        <div>
                            충전 신청 안내
                        </div>
                    </div>
                    <div className={`${styles.sendInfoBox} ${styles.second}`}>
                        <div className={styles.sendInfo}>
                            [충전 신청 내역]
                        </div>
                        <div className={styles.sendInfo}>
                            - 식권수량 : {numbers}장
                        </div>
                        <div className={styles.sendInfo}>
                            - 결제금액 : {(numbers*oneMealTicketPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                        </div>
                        <div className={styles.sendInfo}>
                            - 전송된 번호 : {selectedPhoneNumber}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default MobileMealTicketText;