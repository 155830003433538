import React, { useEffect, useState } from "react";
//import styles from "../../componentsStyle/check.module.css";
import styles from "../styles/newtodo.module.css";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";


const NewTodo: React.FC<any> = (props) => {
    const [alignment, setAlignment] = useState("total");

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: any
    ) => {
        if (!newAlignment) return;
        setAlignment(newAlignment);
    };

    return (
        <div style={{
            display : props.menu === 1 ? "block" : "none"
        }}>
            <div
                style={{
                    width: "1750px",
                    height: "950px",
                }}
            >
                <iframe
                    title="main"
                    src={"https://app.todoist.com/app/281172/projects/active"}
                    style={{ width: "100%", height: "100%", border: "none" }}
                />
            </div>
        </div>
    );
};

export default NewTodo;