import React, { useState, useEffect, useRef, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import styles from "./mobiledashboard.module.css";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { Helmet } from "react-helmet";
import { ReactComponent as LogoSvg } from '../../svg/newlogo.svg';
import { ReactComponent as BellIconSvg } from '../../svg/totalmenu/clock-regular.svg';
import { ReactComponent as CheckListMenu } from "../../svg/checklistmenu.svg";
// import { ReactComponent as BellIconSvg } from '../../svg/mobiletotal/arrow-up-left-from-circle-regular.svg';
// import { ReactComponent as BellIconSvg } from '../../svg/mobiletotal/arrow-right-from-bracket-regular.svg';

import { IonAvatar, IonRippleEffect } from "@ionic/react";
import { GridCellCheckboxForwardRef } from "@mui/x-data-grid";

import { IonLoading } from "@ionic/react";


import { ReactComponent as AlarmClock } from "../../svg/alarm-clock-regular.svg";

import { style } from "@mui/system";
import { Link } from "react-router-dom";
import { includes } from "lodash";
import { useHistory } from "react-router-dom";
import Footer from "../etc/footer";
import { Socket } from "socket.io-client";
import { getAvatarSrc } from "../../etc/functions";

import { InAppBrowser } from "@ionic-native/in-app-browser";
import { Market } from "@ionic-native/market";


import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { LinearProgress } from "@mui/material";
import { englishAcademyNameToKorean, renderLogoSvg } from "../../data/locationFuntion";
import menuList from "../data/menulist";

interface user {
    name: string;
    value: "student" | "teacher" | "parent" | "staff";
    id: number;
    studentName: string | undefined | null;
    studentId: number | undefined | null;
    academy?: string;
    location?: string;
}

interface mobileDashboardProps extends RouteComponentProps {
    user: user | null;
    socket: Socket;
    unreadMessage: number;
    unread: () => void;
}


const heightInfo = [40, 30, 50, 30, 40, 60, 50, 40, 40, 50, 40, 40, 50, 60, 50, 40, 30, 40, 50, 60, 50, 30, 40, 60, 50, 40, 40, 50, 40, 40, 50]




const MobileDashboard: React.FC<mobileDashboardProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [avatarSrc, setAvatarSrc] = useState("");

    const history = useHistory();
    const footerRef = useRef<any>(null);
    const headerRef = useRef<any>(null);


    const [platform, setPlatform] = useState("");
    const [height, setHeight] = useState(0);


    const [graphMenu, setGraphMenu] = useState("");


    const wrapperRef = useRef<any>(null);
    const whiteRef = useRef<any>(null);

    const graphRef = useRef<any>([]);
    const [graphHeight, setGraphHeight] = useState<any>([]);

    const [translateValue, setTranslateValue] = useState("translate(3%, -115%)");

    const [dateStudyTime, setDateStudyTime] = useState<any>();
    const [weekStudyTime, setWeekStudyTime] = useState<any>();
    const [weekDateInfo, setWeekDateInfo] = useState<any>();
    const [monthStudyTime, setMonthStudyTime] = useState<any>();
    const [monthDateInfo, setMonthDateInfo] = useState<any>();

    const [bodyHeight, setBodyHeight] = useState<number>(0);

    const [averageDateTime, setAverageDateTime] = useState<number>(0);
    const [averageWeekTime, setAverageWeekTime] = useState<number>(0);
    const [averageMonthTime, setAverageMonthTime] = useState<number>(0);




    const [fingerprintId, setFingerprintId] = useState<any>();

    const [monthTotalStudent, setMonthTotalStuent] = useState<any>();
    const [weekTotalStudent, setWeekTotalStudent] = useState<any>();

    const [myMonthRankingState, setMyMonthRankingState] = useState<any>();
    const [myWeekRankingState, setMyWeekRankingState] = useState<any>();
    const [myDayRankingState, setMyDayRankingState] = useState<any>();

    const [myMonthTotalStudyTime, setMyMonthTotalStudyTime] = useState<any>();
    const [myWeekTotalStudyTime, setMyWeekTotalStudyTime] = useState<any>();

    const [orderedMonthInfo, setOrderedMonthInfo] = useState<any>();
    const [orderedWeekInfo, setOrderedWeekInfo] = useState<any>();

    const [notifications, setNotifications] = useState<any>();

    const [monthTotalInfo, setMonthTotalInfo] = useState<any>();
    const [weekTotalInfo, setWeekTotalInfo] = useState<any>();
    const [dayTotalInfo, setDayTotalInfo] = useState<any>();
    const [location, setLocation] = useState("out");


    //dashboard 처음 진입 시 외출 중인지 내원 중인지 가져오는 기능-------------------------------
    useEffect(() => {


        async function start() {
            var token: any = "";
            //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }
            //------------------------------------------------------------------

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });

                token = value;
            }

            fetch("https://peetsunbae.com/dashboard/where", {
                method: "GET",
                credentials: "include",
                headers: { 'Authorization': token }
            }).then((response) => {
                response.json()
                    .then((result) => {

                        if (result.message === "success") {



                            if (result.location === "out") {
                                setLocation("out");
                            }
                            if (result.location === "in") {
                                setLocation("in");
                            }

                        } else {

                        }

                    })
            }).catch(error => {

            })
        }

        start();
    }, []);
    //-----------------------------------------------------------------------------------------


    //아바타 그림 소스 가져오기
    useEffect(() => {

        const getAvatar = async () => {
            const avatarSrc: any = await getAvatarSrc();

            setAvatarSrc(avatarSrc);
        }

        getAvatar();

    }, []);


    useEffect(() => {

        if (!headerRef || !footerRef || !headerRef.current || !footerRef.current) {
            return;
        }

        const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

        const caculateBodyHeight = () => {

            if (!headerRef || !footerRef || !headerRef.current || !footerRef.current) {
                return;
            }

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight - footerHeight;
            setBodyHeight(lastHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef, footerRef, platform]);






    useEffect(() => {
        getStudyTime();

    }, []);

    const getStudyTime = async () => {
        setLoading(true);
        var token: any = ""
        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {

            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/home/mobilestudytime", {
            method: "GET",
            credentials: "include",
            headers: { "Authorization": token }
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    if (result.message === "success") {

                        getMyRanking();

                        setWeekDateInfo(result.weekDateInfo.reverse());
                        setMonthDateInfo(result.monthDateInfo.reverse());

                        const dateInfo: any = result.dateInfo;
                        const weekInfo: any = result.weekInfo;
                        const monthInfo: any = result.monthInfo;


                        if (!dateInfo || !weekInfo || !monthInfo) {
                            return;
                        }

                        var dateTotalTime = 0;
                        var weekTotalTime = 0;
                        var monthTotalTime = 0;

                        const dateStudyTime = [0, 0, 0, 0, 0];

                        dateStudyTime.forEach((each: any, index: number) => {
                            if (dateInfo[index]) {
                                dateStudyTime[index] = +dateInfo[index].totalTime;
                                dateTotalTime = dateTotalTime + (+dateInfo[index].totalTime);
                            }
                        })



                        const weekStudyTime = [0, 0, 0, 0, 0];

                        weekStudyTime.forEach((each: any, index: number) => {
                            var totalTime = 0;
                            weekInfo[index].forEach((eachDateInWeek: any) => {
                                totalTime = totalTime + (+eachDateInWeek.totalTime);
                                if (index !== 0) {
                                    weekTotalTime = weekTotalTime + (+eachDateInWeek.totalTime);
                                }
                            })
                            weekStudyTime[index] = totalTime;
                        })

                        const monthStudyTime = [0, 0, 0, 0, 0];

                        monthStudyTime.forEach((each: any, index: number) => {
                            var totalTime = 0;
                            monthInfo[index].forEach((eachDateInMonth: any) => {
                                totalTime = totalTime + (+eachDateInMonth.totalTime);
                                if (index !== 0) {
                                    monthTotalTime = monthTotalTime + (+eachDateInMonth.totalTime);
                                }
                            });
                            monthStudyTime[index] = totalTime;
                        })

                        dateTotalTime = Math.floor(dateTotalTime / 60000);
                        weekTotalTime = Math.floor(weekTotalTime / 60000);
                        monthTotalTime = Math.floor(monthTotalTime / 3600000);

                        setAverageDateTime(Math.floor(dateTotalTime / 5));
                        setAverageWeekTime(Math.floor(weekTotalTime / 4));
                        setAverageMonthTime(Math.floor(monthTotalTime / 4));






                        const dateStudyTimeMax = Math.max(...dateStudyTime);
                        const weekStudyTimeMax = Math.max(...weekStudyTime);
                        const monthStudyTimeMax = Math.max(...monthStudyTime);

                        const finalDateInfo: any = [];
                        const finalWeekInfo: any = [];
                        const finalMonthInfo: any = [];

                        dateStudyTime.forEach((each: any, index: number) => {
                            finalDateInfo[index] = {
                                value: (each === 0 || dateStudyTimeMax === 0) ? 0 : Math.floor((each * 1000) / dateStudyTimeMax) / 1000,
                                string: `${Math.floor(each / 3600000)}H ${Math.floor(each / 60000) % 60}M`
                            }
                        })



                        weekStudyTime.forEach((each: any, index: number) => {
                            finalWeekInfo[index] = {
                                value: (each === 0 || weekStudyTimeMax === 0) ? 0 : Math.floor((each * 1000) / weekStudyTimeMax) / 1000,
                                string: `${Math.floor(each / 3600000)}H ${Math.floor(each / 60000) % 60}M`
                            }
                        })


                        monthStudyTime.forEach((each: any, index: number) => {
                            finalMonthInfo[index] = {
                                value: (each === 0 || monthStudyTimeMax === 0) ? 0 : Math.floor((each * 1000) / monthStudyTimeMax) / 1000,
                                string: `${Math.floor(each / 3600000)}H`
                            }
                        })



                        setDateStudyTime(finalDateInfo.reverse());
                        setWeekStudyTime(finalWeekInfo.reverse());
                        setMonthStudyTime(finalMonthInfo.reverse());
                        setGraphMenu("daily");


                    }

                    setLoading(false);
                })
        })

    }

    const getMyRanking = async () => {


        var token: any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }



        fetch("https://peetsunbae.com/dashboard/home/studytime", {
            method: "GET",
            credentials: "include",
            headers: { "Authorization": token }
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    if (result.message === "success" && result.fingerprintId) {

                        setFingerprintId(result.fingerprintId);
                        const fingerprintId = result.fingerprintId;

                        var myMonthStudyTime = 0;
                        var myWeekStudyTime = 0;
                        var myDayStudyTime = 0;

                        const monthInfo = result.monthInfo;
                        const weekInfo = result.weekInfo;
                        const dayInfo = result.dayInfo;

                        setMonthTotalInfo(monthInfo);
                        setWeekTotalInfo(weekInfo);
                        setDayTotalInfo(dayInfo);

                        monthInfo.forEach((each: any) => {
                            if (each.fingerprintId === fingerprintId) {
                                myMonthStudyTime = each.totalStudyTime;
                            }
                        })

                        setMyMonthTotalStudyTime(myMonthStudyTime);

                        weekInfo.forEach((each: any) => {
                            if (each.fingerprintId === fingerprintId) {
                                myWeekStudyTime = each.totalStudyTime;
                            }
                        })

                        setMyWeekTotalStudyTime(myWeekStudyTime);

                        dayInfo.forEach((each: any) => {
                            if (each.fingerprintId === fingerprintId) {
                                myDayStudyTime = each.totalTime;
                            }
                        })

                        //각 월 1일에는 0이 됨
                        var myMonthRanking = monthInfo.length;

                        setMonthTotalStuent(monthInfo.length);

                        //월요일에는 0이 됨
                        var myWeekRanking = weekInfo.length;

                        //12-2시 사이 0 이 됨
                        var myDayRanking = dayInfo.length;


                        setWeekTotalStudent(weekInfo.length);

                        monthInfo.forEach((each: any) => {
                            if (each.totalStudyTime < myMonthStudyTime) {
                                myMonthRanking--;
                            }
                        })

                        weekInfo.forEach((each: any) => {
                            if (each.totalStudyTime < myWeekStudyTime) {
                                myWeekRanking--;
                            }
                        })

                        dayInfo.forEach((each: any) => {
                            if (+each.totalTime < +myDayStudyTime) {
                                myDayRanking--;
                            }
                        })



                        monthInfo.sort(function (a: any, b: any) {
                            if (a.totalStudyTime < b.totalStudyTime) {
                                return 1;
                            } else if (a.totalStudyTime > b.totalStudyTime) {
                                return -1;
                            } else {
                                return 0;
                            }
                        })

                        weekInfo.sort(function (a: any, b: any) {
                            if (a.totalStudyTime < b.totalStudyTime) {
                                return 1;
                            } else if (a.totalStudyTime > b.totalStudyTime) {
                                return -1;
                            } else {
                                return 0;
                            }
                        })


                        setOrderedMonthInfo(monthInfo);
                        setOrderedWeekInfo(weekInfo);


                        setMyMonthRankingState(myMonthRanking);
                        setMyWeekRankingState(myWeekRanking);
                        setMyDayRankingState(myDayRanking);

                    }
                })
        })


    }

    const test = async () => {

        var token: any = ""
        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {

            const { value } = await Preferences.get({ key: "token" });

            token = value;
        }

    }

    useEffect(() => {
        test();
    }, [])

    useEffect(() => {
        setTimeout(() => {

            const newGraphHeight: any = [];
            graphRef.current.forEach((each: any, index: number) => {
                if (each) {

                    const newHeight = 100 * (+each.dataset.height);
                    newGraphHeight.push(newHeight);
                    setGraphHeight([...newGraphHeight]);
                }
            })


        }, 150);


    }, [graphRef.current, graphMenu]);

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);



    const menuClickHandle = (index: number) => {



        if (!wrapperRef.current || !whiteRef.current) {

            return;
        }

        const wrapperWidth = wrapperRef.current.clientWidth;
        const whiteWidth = whiteRef.current.clientWidth;

        const value0 = whiteWidth * 0.03;
        const value1 = wrapperWidth / 2 - whiteWidth / 2;
        const value2 = wrapperWidth - whiteWidth * 1.03;

        var newTranslateValue: any;
        switch (index) {
            case 0:
                newTranslateValue = value0;
                setGraphMenu("daily");
                break;
            case 1:
                newTranslateValue = value1;
                setGraphMenu("weekly");
                break;
            case 2:
                newTranslateValue = value2;
                setGraphMenu("monthly");
                break;
        }

        if (!newTranslateValue) {
            return;
        }

        const newTranslateValueText = `translate(${newTranslateValue}px, -115%)`;

        setTranslateValue(newTranslateValueText);
    }

    //공지사항 가져오는 기능

    useEffect(() => {
        getNotification();
    }, [])

    const getNotification = async () => {

        var token: any = ""

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {

            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/home/notifications", {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": token
            }
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    if (result.message === "success") {
                        setNotifications(result.data);
                    }
                })
        })
    }


    //시간표 열람-----------------------------------------------------
    const showTimeTable = () => {

        history.push("/mobiledashboard/timetable");

    }
    //-----------------------------------------------------------------------


    const config = () => {

        history.push("/mobiledashboard/profilepicture");

    }

    const goToStudyTimeRanking = () => {
        history.push({
            pathname: "mobiledashboard/studytimeranking",
            state: {
                monthInfo: monthTotalInfo,
                weekInfo: weekTotalInfo,
                dateInfo: dayTotalInfo,
                graphMenu
            }
        })
    }

    const handleNotificationClick = (id: number) => {
        history.push({
            pathname: "/mobiledashboard/mobilenotificationdetailfordashboard",
            state: {
                id
            }
        })
    }



    const goNativeStore = (androidUrl: string, iosUrl: string) => {

        if (Capacitor.getPlatform() === "ios") {

            InAppBrowser.create(iosUrl);
        }

        if (Capacitor.getPlatform() === "android") {

            // InAppBrowser.create(androidUrl);
            Market.open(androidUrl);
        }

    }




    return (
        <div className={`${styles.main} ${platform === "ios" ? styles.ios : ""}`} style={{ paddingTop: "env(safe-area-inset-top)" }}>
            <Helmet>
                <meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, viewport-fit=cover" />
            </Helmet>

            <div className={styles.headerDiv}>
                <div className={`${styles.header}  ${platform === "ios" ? styles.ios : ""}`} ref={headerRef}>
                    <div className={styles.logoDiv}>
                        {/* <LogoSvg className={styles.logo} /> */}
                        {
                            (props.user && props.user.academy) &&
                            renderLogoSvg(props.user.academy, "logo", styles.logo)
                        }
                    </div>
                    <div className={styles.alarmDiv} onClick={showTimeTable}>
                        <AlarmClock className={styles.bellIcon} />
                    </div>
                </div>
            </div>
            {
                loading
                &&
                <LinearProgress />
            }

            {
                !loading
                &&
                <div className={styles.mainBody} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                    <div className={styles.bodyDiv}>
                        <div className={styles.body}>
                            <div className={styles.profileWrapper}>
                                <div className={styles.avatarDiv}>
                                    <IonAvatar className={styles.avatar}>
                                        <img alt="Silhouette of a person's head" src={avatarSrc ? avatarSrc : "https://ionicframework.com/docs/img/demos/avatar.svg"} className={styles.avatarImg} />
                                    </IonAvatar>
                                </div>
                                <div className={styles.profileDescription}>
                                    <div className={styles.profileName}>
                                        {/* 수능선배 */}
                                        {(props.user && props.user.academy) && englishAcademyNameToKorean(props.user.academy)} {props.user && props.user.name} {(props.user && props.user.value === "parent") && `(${props.user.studentName})`}
                                    </div>
                                    <div className={styles.profileDescription}>
                                        {(props.user && props.user.academy) && englishAcademyNameToKorean(props.user.academy)}에 오신걸 환영합니다.<span className={styles.isOut}>{location === "out" ? "외출중" : "공부중"}</span>
                                    </div>
                                </div>
                                <div className={styles.myProfileBtn} onClick={config}>
                                    프로필
                                </div>
                            </div>

                            <div className={styles.hotMenuSection}>
                                <div className={styles.hotMenuDiv}>

                                    {menuList.map((menu) => {
                                        // 1) If `onlyNonParent` is true, but the user is a parent, skip
                                        if (props.user && menu.onlyNonParent && props.user.value === "parent") {
                                            return null;
                                        }

                                        // 2) Check academies. If `menu.academy` is ["total"], show it for everyone
                                        if (!menu.academy.includes("total")) {
                                            // Means it's restricted to certain academies:
                                            if(props.user && props.user.academy && !menu.academy.includes(props.user.academy)) {
                                                return null;
                                            }
                                        }

                                        // 3) Check locations. If `menu.location` is ["total"], show it for everyone
                                        if (!menu.location.includes("total")) {
                                            // Means it's restricted to certain locations:
                                            if(props.user && props.user.location && !menu.location.includes(props.user.location)) {
                                                return null;
                                            }
                                        }

                                        // Otherwise, render
                                        return (
                                            <Link key={menu.id} to={menu.url}>
                                                <div className={styles.hotMenu}>
                                                    <div className={styles.hotMenuIcon}>
                                                        {/* Render the SVG component */}
                                                        {menu.svg()}
                                                    </div>
                                                    <div className={styles.hotMenuText}>{menu.korean}</div>
                                                </div>
                                            </Link>
                                        );
                                    })}

                                    {/* <Link to="/mobiledashboard/attendance">
                                        <div className={styles.hotMenu}>
                                            <div className={styles.hotMenuIcon}>
                                                <Month className={styles.monthIcon} />
                                            </div>
                                            <div className={styles.hotMenuText}>
                                                출석&벌점
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/mobiledashboard/regular">
                                        <div className={styles.hotMenu}>
                                            <div className={styles.hotMenuIcon}>
                                                <Regular className={styles.regularIcon} />
                                            </div>
                                            <div className={styles.hotMenuText}>
                                                정기일정
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/mobiledashboard/consultreport">
                                        <div className={styles.hotMenu}>
                                            <div className={styles.hotMenuIcon}>
                                                <Consult className={styles.consultIcon} />
                                            </div>
                                            <div className={styles.hotMenuText}>
                                                상담일지
                                            </div>
                                        </div>
                                    </Link>
                                    {
                                        (!props.user || props.user.value !== "parent") &&
                                        <Link to="/mobiledashboard/offlinequestion">
                                            <div className={styles.hotMenu}>
                                                <div className={styles.hotMenuIcon}>
                                                    <Offline className={styles.offlineIcon} />
                                                </div>
                                                <div className={styles.hotMenuText}>
                                                    Off 질의응답
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                    {
                                        (!props.user || props.user.value !== "parent") &&
                                        <Link to="/mobiledashboard/onlinequestion">
                                            <div className={styles.hotMenu}>
                                                <div className={styles.hotMenuIcon}>
                                                    <Online className={styles.onlineIcon} />
                                                </div>
                                                <div className={styles.hotMenuText}>
                                                    On 질의응답
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                    <Link to="/mobiledashboard/restaurant">
                                        <div className={styles.hotMenu}>
                                            <div className={styles.hotMenuIcon}>
                                                <Meal className={styles.mealIcon} />
                                            </div>
                                            <div className={styles.hotMenuText}>
                                                도시락 신청
                                            </div>
                                        </div>
                                    </Link>
                                    {
                                        (!props.user || props.user.value !== "parent") &&
                                        <Link to="/mobiledashboard/opinion">
                                            <div className={styles.hotMenu}>
                                                <div className={styles.hotMenuIcon}>
                                                    <Opinion className={styles.opinionIcon} />
                                                </div>
                                                <div className={styles.hotMenuText}>
                                                    의견 보내기
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                    {
                                        ((!props.user || props.user.value !== "parent") && (props.user && (props.user.academy === "sunbae" || props.user.academy === "molip" || props.user.academy === "bundangsunbae"))) &&
                                        <Link to="/mobiledashboard/wordtest">
                                            <div className={styles.hotMenu}>
                                                <div className={`${styles.hotMenuIcon} ${styles.abc}`}>
                                                    <Abc className={styles.abcIcon} />
                                                </div>
                                                <div className={styles.hotMenuText}>
                                                    영단어 시험
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                    {
                                        ((!props.user || props.user.value !== "parent") && (props.user && props.user.academy === "mom")) &&
                                        <Link to="/mobiledashboard/checklist">
                                            <div className={styles.hotMenu}>
                                                <div className={`${styles.hotMenuIcon}`}>
                                                    <CheckListMenu className={styles.checkIcon} />
                                                </div>
                                                <div className={styles.hotMenuText}>
                                                    스케쥴노트 검사
                                                </div>
                                            </div>
                                        </Link>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.divider}>

                    </div>

                    <div className={styles.bodyDiv}>
                        <div className={styles.body}>

                            <div className={styles.studyTimeSection}>
                                <div className={styles.studyTimeDetail} onClick={goToStudyTimeRanking}>
                                    #자세히
                                </div>
                                <div className={styles.studyTimeTitle}>
                                    {
                                        ((averageDateTime || averageDateTime === 0) && (averageWeekTime || averageWeekTime === 0) && (averageMonthTime || averageMonthTime === 0)) &&
                                            graphMenu === "daily" ?
                                            `평균 ${Math.floor(averageDateTime / 60)}시간 ${averageDateTime % 60}분`
                                            : graphMenu === "weekly" ?
                                                `평균 ${Math.floor(averageWeekTime / 60)}시간 ${averageWeekTime % 60}분`
                                                : graphMenu === "monthly" ?
                                                    `평균 ${averageMonthTime}시간`
                                                    :
                                                    ""
                                    } 정도 공부해요!
                                </div>
                                <div className={styles.studyTimeSubTitme}>
                                    <span className={`${styles.ranking} ${graphMenu === "daily" ? styles.active : ""}`}>
                                        {
                                            myDayRankingState ?
                                                `어제 공부시간 등수는 ${myDayRankingState}등 이에요`
                                                :
                                                "공부시간 데이터 수집 중입니다. 🧐"
                                        }
                                    </span>
                                    <span className={`${styles.ranking} ${graphMenu === "weekly" ? styles.active : ""}`}>
                                        {
                                            myWeekRankingState ?
                                                `이번 주 공부시간 등수는 ${myWeekRankingState}등 이에요`
                                                :
                                                "공부시간 데이터 수집 중입니다. 🧐"
                                        }
                                    </span>
                                    <span className={`${styles.ranking} ${graphMenu === "monthly" ? styles.active : ""}`}>
                                        {
                                            myMonthRankingState ?
                                                `이번 달 공부시간 등수는 ${myMonthRankingState}등 이에요`
                                                :
                                                "공부시간 데이터 수집 중입니다. 🧐"
                                        }
                                    </span>
                                </div>

                                <div className={styles.graphWrapper}>

                                </div>

                                <div ref={wrapperRef} className={styles.studyTimeSelectMenuDiv}>

                                    <div className={styles.graphDiv}>
                                        {
                                            new Array(5).fill(undefined).map((each, index: number) => {
                                                if (!dateStudyTime) {
                                                    return;
                                                }

                                                const newDate = new Date();
                                                newDate.setDate(newDate.getDate() - 1);
                                                const dailyDate: any = [];
                                                for (var i = 0; i < 5; i++) {
                                                    const dateValueString = `${newDate.getMonth() + 1}.${newDate.getDate()}`;
                                                    dailyDate.unshift(dateValueString);
                                                    newDate.setDate(newDate.getDate() - 1);
                                                }

                                                return (
                                                    <div key={index} className={styles.graphWrapper}>
                                                        <div className={styles.graph}>
                                                            <div className={styles.graphText}>
                                                                {
                                                                    graphMenu === "daily" ?
                                                                        dateStudyTime[index].string
                                                                        :
                                                                        graphMenu === "weekly" ?
                                                                            weekStudyTime[index].string
                                                                            :
                                                                            graphMenu === "monthly" ?
                                                                                monthStudyTime[index].string
                                                                                :
                                                                                ""
                                                                }
                                                            </div>
                                                            <div style={{ height: `${graphHeight[index]}px`, width: "40px" }} ref={(el) => { graphRef.current[index] = el; }} data-height={graphMenu === "daily" ? dateStudyTime[index].value : graphMenu === "weekly" ? weekStudyTime[index].value : graphMenu === "monthly" ? monthStudyTime[index].value : 0} className={`${styles.graphHeight} ${index === 4 ? styles.active : ""}`}>

                                                            </div>
                                                            <div className={styles.graphDate}>
                                                                {
                                                                    graphMenu === "daily" ?
                                                                        dailyDate[index] :
                                                                        (graphMenu === "weekly" && index === 4) ?
                                                                            "이번주" :
                                                                            (graphMenu === "weekly") ?
                                                                                weekDateInfo[index] + "-" :
                                                                                (graphMenu === "monthly" && index === 4) ?
                                                                                    "이번달"
                                                                                    :
                                                                                    (graphMenu === "monthly") ?
                                                                                        monthDateInfo[index] + "월" :
                                                                                        ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                    <div className={styles.studyTimeSelectMenuWrapper}>
                                        <div className={`${styles.studyTimeSelectMenuText} ${graphMenu === "daily" ? styles.active : ""}`} onClick={() => { menuClickHandle(0) }}>
                                            일별
                                        </div>
                                        <div className={`${styles.studyTimeSelectMenuText} ${graphMenu === "weekly" ? styles.active : ""}`} onClick={() => { menuClickHandle(1) }}>
                                            주별
                                        </div>
                                        <div className={`${styles.studyTimeSelectMenuText} ${graphMenu === "monthly" ? styles.active : ""}`} onClick={() => { menuClickHandle(2) }}>
                                            월별
                                        </div>
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <div ref={whiteRef} className={styles.whiteWrapper} style={{ transform: translateValue }}>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={styles.divider2}>
                    </div>

                    <div className={styles.bodyDiv}>
                        <div className={styles.body}>
                            <div className={styles.notificationTitleDiv}>
                                <div className={styles.notificationTitle}>
                                    공지사항
                                </div>
                                <div className={styles.notificationPlusBtn} onClick={(e: any) => { history.push("/mobiledashboard/notificationlist"); }}>
                                    #더보기
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.divider3}>
                    </div>


                    <div className={styles.bodyDiv2}>
                        <div className={styles.body2}>
                            {
                                notifications &&
                                notifications.map((eachNotification: any, index: number) => {
                                    return (
                                        <div key={eachNotification.id} className={`${styles.notificationBox} ${index % 2 === 0 ? styles.even : ""}`} onClick={(e: any) => { handleNotificationClick(eachNotification.id) }} >
                                            <div className={styles.notificationKind}>
                                                공지
                                            </div>
                                            <div className={styles.notificationDescription}>
                                                {eachNotification.title}
                                            </div>
                                            <div className={`${styles.newNotification} ${eachNotification.isRead ? styles.none : ""}`}>
                                                new
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>


                    <div className={styles.voidHeight} style={{ height: "30px" }}>
                    </div>
                </div>

            }

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}


            <Footer unreadMessage={props.unreadMessage} footerRef={footerRef} unread={props.unread} location="home" />

        </div>
    )
}

export default MobileDashboard;