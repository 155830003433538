import React, {useEffect, useState} from "react";
import { getFetch } from "../../../../../fetch/fetch";
import { englishLocationToKorean } from "../../../../../data/locationFuntion";
import { dateToDateString } from "../../etc/etcfunctions";
import { tableUseStyle } from "../../etc/tableusestyle";
import { DataGridPremium, GridRowModel } from "@mui/x-data-grid-premium";

const MentorDescriptionWriteList : React.FC<any> = (props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState<any>([
        { field : "name", headerName : "멘토 이름", width : 200 },
        { field : "location", headerName : "일하는 지점", width : 150 },
        { field : "isFinish", headerName : "작성 완료 여부", width : 100 },
        { field : "teacherName", headerName : "작성자", width : 100 },
        { field : "date", headerName : "업데이트 날짜", width : 200 },
        { field : "memo", headerName : "메모", width : 200, editable : true }
    ]);
    const classes = tableUseStyle();
    const [update, setUpdate] = useState<number>(0);

    useEffect(() => {

        getTotalMentorDescription();

    }, [update]);


    const getTotalMentorDescription = async () => {

        try{

            setLoading(true);

            
            //await fetch 여러개 한번에
            const fetchPromises = [];

            fetchPromises.push(async () => {
                const result : any = await getFetch("/dashboard/avatar/totalteachers");
                return result.data;
            });

            const getFullMentorDescriptionInfo = async () => {

                try{
                    const response : any = await fetch("https://suneungsunbae.com/api/upload/mentorDescriptionInfoFull");

                    const result = await response.json();

                    if(result.message === "success"){
                        return result;
                    }else{
                        new Error("getFullMentorDescriptionInfo error");
                    }

                }catch(e){
                    
                }

            }

            fetchPromises.push(getFullMentorDescriptionInfo);

            const result = await Promise.all(fetchPromises.map((fetchPromise) => fetchPromise()));

            

            const userList : any = result[0];
            const mentorDescriptionInfo : any = result[1].data;
            const memoList : any = result[1].data2;


            mentorDescriptionInfo.forEach((mentorDescription : any) => {

                mentorDescription.location = "";

                mentorDescription.locationList.forEach((location : any) => {

                    if(!location){
                        return;
                    }

                    const koreanLocation = englishLocationToKorean(location);

                    mentorDescription.location += koreanLocation;

                    if(location !== mentorDescription.locationList[mentorDescription.locationList.length - 1]){
                        mentorDescription.location += ", ";
                    }

                })

            });

            const newRows : any = [];

            

            userList.forEach((user : any) => {

                if(user.job !== "mentor"){
                    return;
                }

                const oneRow : any  = {};

                oneRow.id = user.id;
                oneRow.isFinish = false;
                oneRow.name = user.name;

                memoList.forEach((memo : any) => {
                    if(memo.userId === user.id){
                        oneRow.memo = memo.description;
                    }
                });

                mentorDescriptionInfo.forEach((mentorDescription : any) => {
                    if(mentorDescription.userId === user.id){
                        oneRow.userName = mentorDescription.userName;
                        oneRow.location = mentorDescription.location;
                        oneRow.isFinish = true;
                        const date = new Date(mentorDescription.updatedAt);
                        const dateString = dateToDateString(date, true);
                        oneRow.date = dateString;

                        userList.forEach((user : any) => {
                            if(user.id === mentorDescription.teacherId){
                                oneRow.teacherName = user.name;
                            }
                        });

                    }
                });

                newRows.push(oneRow);

            });

            

            newRows.sort((a : any, b : any) => {
                if(a.isFinish && b.isFinish){
                    return 0;
                }else if(a.isFinish){
                    return -1;
                }else if(b.isFinish){
                    return 1;
                }
            })

            setRows([...newRows]);

            setLoading(false);

        }catch(e){
            
        }

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const userId = newRow.id;


            if (!field) {
                return newRow;
            }

            if (!userId) {
                return newRow;
            }

            const data = {
                userId,
                value,
                field
            }

            fetch("https://suneungsunbae.com/api/upload/mentorDescriptionInfo", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            setUpdate(Math.random());
                        }
                    })
            })

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {

        alert(error.message);

    }, []);

    return (
        <div style={{
            paddingTop : "60px"
        }}>
            <div className={classes.root} style={{width : "1000px", height : "500px", backgroundColor : "white"}}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    density="compact"
                    getCellClassName={(params : any) => {
                        
                        if(params.field === "isFinish"){
                            if(params.value === true){
                                return "bluetext"
                            }else{
                                return "redtext"
                            }
                        }

                        return ""
                    }}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />
            </div>
        </div>
    )
}

export default MentorDescriptionWriteList;