import React, { useEffect, useState } from "react";


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RegularMealStatus from "./regularmealstatus";
import RegularMealCalendar from "./regularmealcalendar";
import { koreanLocationToEnglish } from "../../../../data/locationFuntion";
import { getFetch } from "../../../../fetch/fetch";



const RegularMealStatusTotal: React.FC<any> = (props) => {

    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }; 

    return (
        <div>
            <div style={{ width: "1500px", margin: "0 auto", paddingTop : "40px" }}>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="날짜별로 보기" value={0} />
                            <Tab label="표로 보기" value={1} />
                        </Tabs>
                    </Box>
                </Box>
            </div>


            <div>
                {
                    value === 0
                    ?
                    <RegularMealCalendar 
                    user={props.user}
                    />
                    :
                    ""
                }


                {
                    value === 1 
                    ?
                    (
                        <RegularMealStatus
                        />
                    )
                    :
                    ""
                }
            </div>
        </div>
    )
}

export default RegularMealStatusTotal;