import React, { useEffect, useState } from 'react';
import styles from "./styles/regularmeal.module.css";
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import { Button } from '@mui/joy';
import { getFetch, postFetch } from '../../../../fetch/fetch';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { da } from 'date-fns/locale';



const RegularMeal: React.FC<any> = (props) => {

    const [dateValue, setDateValue] = useState<Date>(new Date());
    const [minDate, setMinDate] = useState<Date>(new Date());
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const notify1 = () => toast.success("저장 성공!", {
            position : "bottom-right",
            autoClose : 3000,
            hideProgressBar : true,
            theme : "light"
    });

    const notify2 = () => toast.error("저장 실패!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "light"
    });

    const makeInitialData = () => {

        const initialData = [];

        for (let i = 0; i < 6; i++) {
            const oneRow = {
                lunch: ["", "", "", "", "", "", ""],
                dinner: ["", "", "", "", "", "", ""]
            }

            initialData.push(oneRow);
        }

        return initialData;
    }

    useEffect(() => {

        const initialData = makeInitialData();

        setData([...initialData]);

        getData();

    }, [dateValue]);

    useEffect(() => {

        //dateValue를 다음 주 월요일로 설정
        const today = new Date().getDay();

        if (today === 1) {
            return;
        }

        const diff = 1 - today;

        const nextMonday = new Date();
        nextMonday.setDate(nextMonday.getDate() + diff + 7);

        setDateValue(nextMonday);

    }, []);


    const disableExceptMonday = (date: Date) => {
        return date.getDay() !== 1;
    }

    const handleChange = (dateIndex : number, lunchOrDinner : string, mealIndex : number, value: string) => {

        const newData = [...data];
        newData[dateIndex][lunchOrDinner][mealIndex] = value;

        setData(newData);

    }

    const pasteFromClipboard = async () => {

        try {
            const text = await navigator.clipboard.readText();
            
            const lines = text
        .split(/\r?\n/)          // \r\n(윈도우) or \n(맥/리눅스) 대응
        .map(line => line.trim()) 

        const cleanedArray = lines.map(item =>
            item
              // 1) 영문 괄호(`(` `)`) 내용 제거
              .replace(/\([^)]*\)/g, "")
              // 2) 전각 괄호(`（` `）`) 내용 제거
              .replace(/（[^）]*）/g, "")
              .trim()
          );



            makeLinesToData(cleanedArray);

          } catch (err) {
            console.error("Failed to read clipboard contents: ", err);
          }

    }

    const makeLinesToData = (lines: string[]) => {

        const initialData = makeInitialData();

        lines.forEach((line, index) => {

            var lunchOrDinner : "lunch" | "dinner" = index < 42 ? "lunch" : "dinner";
            const targetIndex = index < 42 ? index : index - 42;

            const dateIndex = targetIndex % 6;
            const mealIndex = Math.floor(targetIndex / 6);

            initialData[dateIndex][lunchOrDinner][mealIndex] = line;

        });

        setData([...initialData]);

    }

    const getData = async () => {

        try{

            const targetTime = dateValue.getTime();

            //qeurystring으로 get으로 요청

            const result : any = await getFetch(`/dashboard/restaurant/regularmeal/getForTeacher?time=${targetTime}`);

            if(result.message === "success") {

                if(!result.data) {
                    return;
                }

                setData(result.data.data);
            }

        }catch(e){
            console.error(e);
        }

    }

    const submit = async () => {

        if (!data) {
            return;
        }

        try {

            setLoading(true);

            const targetTime = dateValue.getTime();

            const sendData = {
                time: targetTime,
                data: data
            }

            const result : any = await postFetch("/dashboard/restaurant/regularmeal/submit", sendData);

            if(result.message === "success") {
                notify1();
            }else{
                new Error("error");
            }

            setLoading(false);

        } catch (e) {
            notify2();
        }

    }

    return (
        <div>
            <div className={styles.btnsDiv}>
                <Button variant="soft" color="primary" onClick={pasteFromClipboard}>
                    클립보드 붙여넣기
                </Button>
            </div>
            <div className={styles.container}>
                <div className={styles.calendarDiv}>
                    <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            shouldDisableDate={disableExceptMonday}
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={dateValue}
                            // disablePast
                            //minDate={minDate}
                            onChange={(newValue: any) => {
                                setDateValue(newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div className={styles.tableDiv}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    new Array(6).fill(0).map((_, index) => {

                                        const targetDate = new Date(dateValue);
                                        targetDate.setDate(targetDate.getDate() + index);

                                        const month = targetDate.getMonth() + 1;
                                        const monthString = month < 10 ? `0${month}` : `${month}`;

                                        const date = targetDate.getDate();
                                        const dateString = date < 10 ? `0${date}` : `${date}`;

                                        const day = targetDate.getDay();
                                        const dayString = ["일", "월", "화", "수", "목", "금", "토"][day];

                                        return (
                                            <th key={index}>
                                                <div>
                                                    {`${monthString}/${dateString} (${dayString})`}
                                                </div>
                                            </th>
                                        )

                                    })
                                }
                            </tr>
                            {
                                new Array(14).fill(0).map((_, rowIndex) => {
                                    return (
                                        <tr key={rowIndex} className={styles.rowStyle}>
                                            {
                                                new Array(7).fill(0).map((_, colIndex) => {

                                                    if (colIndex === 0 && (rowIndex === 0 || rowIndex === 7)) {
                                                        return (
                                                            <td key={colIndex} rowSpan={7} className={styles.mealType}>
                                                                {rowIndex === 0 ? "중식" : "석식"}
                                                            </td>
                                                        )
                                                    } else if (colIndex === 0) {
                                                        return;
                                                    }

                                                    const dateIndex = colIndex - 1;
                                                    const lunchOrDinner = rowIndex < 7 ? "lunch" : "dinner";
                                                    const mealIndex = rowIndex < 7 ? rowIndex : rowIndex - 7;

                                                    return (
                                                        <td key={colIndex}
                                                        className={`${rowIndex === 6 || rowIndex === 13 ? styles.lastRow : ""} ${styles.colStyle}`}
                                                        >
                                                            <div>
                                                                <input type="text" className={styles.inputStyle}
                                                                value={data ? data[dateIndex][lunchOrDinner][mealIndex] : ""}
                                                                onChange={(e) => handleChange(dateIndex, lunchOrDinner, mealIndex, e.target.value)}
                                                                />
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )

                                })
                            }
                        </thead>
                    </table>
                </div>
            </div>
            <div className={styles.submitBtnDiv}>
                <Button color="primary" size='lg' sx={{
                    width : "150px"
                }}
                onClick={submit}
                >
                    저장
                </Button>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default RegularMeal;