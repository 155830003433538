import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilerestaurant.module.css";
import RadioGroup from '@mui/joy/RadioGroup';
import Radio, { radioClasses } from '@mui/joy/Radio';

import { returnDayString } from "../etc/functions";

import Sheet from '@mui/joy/Sheet';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { CssVarsProvider } from '@mui/joy/styles';

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { CircularProgress } from "@mui/material";

import { IonLoading, IonToast } from '@ionic/react';

import { useIonActionSheet } from '@ionic/react';




const MobileRestaurantSubmit: React.FC<any> = (props) => {

    const [firstWeekInfo, setFirstWeekInfo] = useState<any>();
    const [secondWeekInfo, setSecondWeekInfo] = useState<any>();
    const [today, setToday] = useState(new Date());




    const imageBoxRef = useRef<any>(null);
    const [height, setHeight] = useState(120);
    const [isSafeArea, setIsSafeArea] = useState(false);


    const [loading, setLoading] = useState(false);
    const [mealLoading, setMealLoading] = useState(false);

    const [chargedAmount, setChargedAmount] = useState(0);

    const [deadline, setDeadline] = useState<any>();
    const [selectedMealId, setSelectedMealId] = useState(0);
    const [selectedMealName, setSelectedMealName] = useState("");
    const [selectedMealPrice, setSelectedMealPrice] = useState(0);
    const [selectedRestaurantName, setSelectedRestaurantName] = useState("");

    const [mealMenu, setMealMenu] = useState<any>([]);

    const [lackOfBanlance, setLackOfBalance] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [timeOut, setTimeOut] = useState(false);
    const [success, setSuccess] = useState(false);
    const [nonSelect, setNonSelect] = useState(false);
    const [nonSelectMeal, setNonSelectMeal] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [showToast2, setShowToast2] = useState(false);

    const [present] = useIonActionSheet();


    useEffect(() => {

        const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

        if (valueBottom > 0) {
            setIsSafeArea(true);
        }



        const height = Math.floor((window.innerWidth * 120) / 375);



        setHeight(height);

    }, []);




    useEffect(() => {

        setSelectedMealName("");
        setSelectedRestaurantName("");
        setSelectedMealPrice(0);

        getInfo();

    }, [props.targetDateIndex, props.whenType]);


    const getInfo = async () => {

        setMealLoading(true);

        const dateValue = new Date(today.getTime());
        dateValue.setDate(dateValue.getDate() + props.targetDateIndex);

        if (!dateValue || !props.whenType) {
            return;
        }

        var token: any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        fetch(`https://peetsunbae.com/dashboard/restaurant/start?dateValue=${new Date(dateValue).getTime()}&whenType=${props.whenType}`, {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    setChargedAmount(result.amount);
                    setMealMenu(result.meal);
                    setDeadline(result.deadline);
                    const array: any = [];
                    if (result.meal.length > 0) {
                        result.meal.forEach((each: any) => {
                            array.push(each.restaurantName);
                        });
                        const set: any = new Set(array);
                    }
                    setMealLoading(false);
                })
        })
    }



    const handleMealClick = (e: any) => {
        const selectedId = e.target.value;


        var newRestaurantName = "";
        var newMealName = "";
        var newPrice = 0;

        if (!mealMenu) {

            return;
        }

        mealMenu.forEach((eachMeal: any) => {
            if (+eachMeal.id === +selectedId) {
                newRestaurantName = eachMeal.restaurantName;
                newMealName = eachMeal.mealName;
                newPrice = eachMeal.mealPrice;
            }
        })

        setSelectedRestaurantName(newRestaurantName);
        setSelectedMealName(newMealName);
        setSelectedMealPrice(newPrice);
        setSelectedMealId(+selectedId);
    }


    const submit = async (forced : boolean = false) => {


        const dateValue = new Date(today.getTime());
        dateValue.setDate(dateValue.getDate() + props.targetDateIndex);

        if (+chargedAmount < +selectedMealPrice) {

            setToastMessage("😔 잔액이 부족합니다");
            setShowToast(true);

        } else if (!props.whenType) {

            setToastMessage("😔 점심/저녁을 골라주세요");
            setShowToast(true);

        } else if (!selectedMealName || !selectedMealId) {
            setToastMessage("😔 메뉴를 선택해주세요");
            setShowToast(true);
        } else if (!dateValue) {
            setToastMessage("😔 날짜를 선택해주세요");
            setShowToast(true);
        }
        else if (dateValue) {
            setLoading(true);
            const date = new Date(dateValue);


            var token: any = "";

            if (window.electron) {
                token = window.electron.sendMessageApi.getToken();
            }

            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }


            fetch("https://peetsunbae.com/dashboard/restaurant/order", {
                method: "POST",
                headers: { "Authorization": token, "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    targetYear: date.getFullYear(),
                    targetMonth: date.getMonth() + 1,
                    targetDate: date.getDate(),
                    whenType: props.whenType,
                    restaurantName: selectedRestaurantName,
                    mealName: selectedMealName,
                    price: selectedMealPrice,
                    mealId: selectedMealId,
                    forced : forced
                })
            }).then((response) => {
                response.json()
                    .then((response) => {
                        setLoading(false);

                        if (response.message === "TIME_OUT") {
                            setToastMessage("😔 주문 시간이 지났습니다. 점심 9:30, 저녁 14:30 까지");
                            setShowToast(true);
                        } else if (response.message === "LACK_BALANCE") {
                            setToastMessage("😔 잔액이 부족합니다");
                            setShowToast(true);
                        } else if (response.message === "success") {
                            setSuccess(true);
                            setShowToast2(true);
                        } else if (response.message === "DUPLICATE") {
                            setToastMessage("😔 해당 날짜에 이미 주문했습니다");
                            setShowToast(true);
                        } else if (response.message === "DUPLICATE2"){
                            present({
                                header: "도시락 중복 주문",
                                subHeader: "해당 날짜에 정기식 주문이 있습니다. 그대로 진행하시겠습니까?",
                                buttons: [{
                                    text: "네",
                                    data : {
                                        action : "yes"
                                    }
                                },
                                {
                                    role: "cancel",
                                    text: "아니오",
                                    data : {
                                        action : "no"
                                    }
                                }
                                ],
                                onDidDismiss: ({ detail }) => {
                                    switch (detail.data.action) {
                                        case "yes":
                                            submit(true);
                                            break;
                                        case "no":
                                            break;
                                    }
                                },
                            })
                        }

                        props.letsUpdate();

                    })
            }).catch((error) => {

            })


        }
    }




    return (
        <div>
            <div className={styles.body}>


                <div className={styles.menuWrapper}>
                    {
                        !mealLoading &&
                        <CssVarsProvider>
                            <RadioGroup
                                onChange={(e: any) => { handleMealClick(e); }}
                                aria-label="platform"
                                defaultValue="Website"
                                overlay
                                name="platform"
                                sx={{
                                    flexDirection: 'row',
                                    gap: 2,
                                    [`& .${radioClasses.checked}`]: {
                                        [`& .${radioClasses.action}`]: {
                                            inset: -1,
                                            border: '3px solid',
                                            borderColor: 'primary.500',
                                        },
                                    },
                                    [`& .${radioClasses.radio}`]: {
                                        display: 'contents',
                                        '& > svg': {
                                            zIndex: 2,
                                            position: 'absolute',
                                            top: '-8px',
                                            right: '-8px',
                                            bgcolor: 'background.body',
                                            borderRadius: '50%',
                                            // fill : "#263238"
                                        },
                                    },
                                }}
                            >
                                {mealMenu && mealMenu.map((eachMenu: any) => (
                                    <div key={eachMenu.id}>
                                        <Sheet
                                            variant="outlined"
                                            sx={{
                                                borderRadius: 'md',
                                                bgcolor: 'background.level1',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: 1.5,
                                                width: height,
                                                height: height,
                                                position: "relative"
                                            }}
                                        >
                                            <Radio id={`${eachMenu.id}`} value={eachMenu.id} checkedIcon={<CheckCircleRoundedIcon />} />
                                            {/* <Avatar variant="soft" size="sm" />
                                    <FormLabel htmlFor={value}>{value}</FormLabel> */}
                                            <div className={styles.mealImageWrapper} ref={imageBoxRef}>
                                                <img className={styles.mealImage} src={`https://peetsunbae.com/${eachMenu.src.split("/public/")[1]}`} />
                                            </div>
                                        </Sheet>
                                        <div className={styles.mealDescription}>
                                            <div className={styles.restaurantName}>
                                                {eachMenu.restaurantName}
                                            </div>
                                            <div className={styles.mealName} style={{ maxWidth: `${height}px` }}>
                                                {eachMenu.mealName}
                                            </div>
                                            <div className={styles.mealName}>
                                                {eachMenu.mealPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </RadioGroup>
                        </CssVarsProvider>
                    }
                    {
                        mealLoading &&
                        <div className={styles.loadingDiv} style={{ height: height }}>
                            <CircularProgress />
                        </div>
                    }

                </div>
            </div>

            <div onClick={() => {submit()}} className={`${styles.submitButton} ${isSafeArea ? styles.safeArea : ""}`}>
                도시락 주문하기
            </div>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => { setShowToast2(false); }}
                message="😃 주문에 성공했습니다 !"
                duration={1500}
                position="top"
                color="primary"
            />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            />

        </div>
    );
}

export default MobileRestaurantSubmit;