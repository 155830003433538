import React, { useEffect, useRef, useState } from 'react';

// import styles from './styles';
// import { withStyles } from '@mui/styles';

import styles from "./dashboard.module.css"
import Home from './components/home';
import Alarm from './components/alarm';
import Attendance from './components/attendance';
import Avatar from './components/avatar';
import Book from './components/book';
import Chart from './components/chart';
import Edit from './components/edit';
import Envelope from './components/envelope';
import Notification from './components/notification';
import Question from './components/question';
import Report from './components/report';
import Restaurant from './components/restaurant';
import Search from './components/search';
import NotificationWrite from './components/notificationwrite';
import { ReactComponent as LogoSvg } from '../svg/newlogowhite.svg';
import { ReactComponent as ShapeLogoSvg } from '../svg/onlyshape.svg';
import {ReactComponent as MomWhiteLogoSvg} from "../svg/logo/mom/whitelogo.svg";

import menulist from './components/menulist'
import { forEachChild } from 'typescript';

import { Route, HashRouter as Router, Link, Switch } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import QuestionWrite from './components/questionwrite';
import Profile from './components/profile';
import NotificationDetail from './components/controls/notificationdetail';
import TemporaryDrawer from './components/drawer';
import Text from './components/test';
import { Helmet } from 'react-helmet';
// import { ipcRenderer } from 'electron';
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

import nativePushRegistartion from "../service/pushnotification";
import app from "../service/firebaseinit";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { useHistory } from "react-router-dom";
import { IonAvatar, useIonAlert } from "@ionic/react";
import { getAvatarSrc } from '../etc/functions';
import { getFetch } from '../fetch/fetch';
import Diagnosis from './components/diagnosis';

import { App, AppState } from "@capacitor/app";
import Check from './components/check';
import SeatPractice from './components/controls/seatpracitce';
import Heart from './components/heart';
import WifiPermit from './components/controls/wifipermit/wifipermit';
import WifipermitForPc from './components/wifipermitforpc';
import Iot from './components/iot';
import { englishAcademyNameToKorean, renderLogoSvg, returnNeedAuthorizeAcademy } from '../data/locationFuntion';
import Checklist from './components/controls/checklist/checklist';
import Money from './components/controls/money/money';
import Booking from './components/controls/booking/booking';
import NewTodo from './components/controls/newtodo/components/check';
import NewTodoMain from './components/controls/newtodo/main';



interface props {
    // classes: any;
    history: any;
    location: any;
    socket: Socket;
}

interface user {
    name: string;
    value: "student" | "teacher" | "parent" | "staff";
    id: number;
    academy?: string;
    location?: string;
    job?: string;
    todoistApiKey?: string;
}

type currentSideBarMenuList = "avatar" | "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고" | "text" | "diagnosis" | "check" | "heart" | "wifipermit" | "wifipermitregister";



const Dashboard: React.FC<props> = (props) => {
    // const classes = props.classes;
    const socket = props.socket;
    const [count, setCount] = useState(0);
    const [familyCount, setFamilyCount] = useState(0);
    const [opinionCount, setOpinionCount] = useState(0);
    const [user, setUser] = useState<user | null>();
    const [location, setLocation] = useState<string>("out");
    const [sideBarMenuList, setSideBarMenuList] = useState<any>();
    const [currentSideBarMenuList, setCurrentSideBarMenuList] = useState<currentSideBarMenuList>("home");

    const [newNotification, setNewNotification] = useState(1);
    const [notificationReadUpdate, setNotificationReadUpdate] = useState(0);

    const [newLocation, setNewLocation] = useState(1);

    const [messageUpdate, setMessageUpdate] = useState(0);
    const [familyMessageUpdate, setFamilyMessageUpdate] = useState({number : 0, isUpdateNeeded : true});

    const history = useHistory();
    const [presentAlert] = useIonAlert();

    const [avatarSrc, setAvatarSrc] = useState<string | null>();

    const [notificationCount, setNotificationCount] = useState(0);
    const [undoTodoCount, setUndoTodoCount] = useState(0);
    const [isDionFoodUser, setIsDionFoodUser] = useState(false);

    const handleStateChange = (state: AppState) => {
        
        
        if (state.isActive) {
            unreadMessage();
            getNotificationNumber();
        }
    }

    //스마트폰 백그라운드에서 포어그라운드로 올 때 한번 더 공지사항 새로운거 채팅 새로운거 확인해주기
    useEffect(() => {

        App.addListener("appStateChange", handleStateChange);

        return () => {
            App.removeAllListeners();
        }

    }, []);



    useEffect(() => {
        
    })

    //notification count check 하는 기능-----------------------------------------
    const getNotificationNumber = async () => {

        // if (props.location === "notification") {
        //     return;
        // }
        try {
            const result: any = await getFetch("/dashboard/notification/getNumber");
            
            
            if (result.message === "success") {
                setNotificationCount(result.count);
            }


        } catch (e) {
            
            return;
        }
    }

    //해결 안된 건의사항 count check 하는 기능-----------------------------------------
    const getOpinionNumber = async () => {

        // if (props.location === "notification") {
        //     return;
        // }
        try {
            const result: any = await getFetch("/dashboard/search/count");
            
            
            if (result.message === "success") {
                setOpinionCount(result.count);
            }
        }
        catch (e) {
            
            return;
        }
    }

    useEffect(() => {
        getOpinionNumber();
    }, []);

    const handleReadNotification = () => {

        const randomNumber = Math.random();
        setNotificationReadUpdate(randomNumber);

    }

    useEffect(() => {
        getNotificationNumber();
    }, [newNotification, notificationReadUpdate]);
    //---------------------------------------------------------------------------

    //답장 안한 message 갯수 물어보는 기능
    const unreadMessage = async () => {
        var token: any = "";
        //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }
        //------------------------------------------------------------------

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/number", {
            method: "GET",
            credentials: "include",
            headers: { 'Authorization': token }
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    
                    setCount(result.data);
                    const familyUnreadNumber = result.familyUnreadNumber;
                    setFamilyCount(familyUnreadNumber);
                })
        }).catch(error => {
            
        })
    }

    useEffect(() => {

        if (!user) {
            
            return;
        }

        if (user.value !== "staff" && user.value !== "teacher") {
            
            return;
        }

        undoTodos();

    }, [user]);

    const undoTodos = async () => {

        // if(!user){
        //     
        //     return;
        // }

        // if(user.value !== "staff" && user.value !== "teacher"){
        //     
        //     return;
        // }

        fetch("https://peetsunbae.com/dashboard/report/counttodos", {
            method: "GET",
            credentials: "include",
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    if (result.message === "success") {
                        setUndoTodoCount(result.todoCount);
                    }
                })
        })

    }


    //처음 dashboard 진입 시 토큰 가지고 있는지랑 가지고 있다면 토큰 정보 받기-------
    useEffect(() => {

        unreadMessage();

        async function start() {
            var token: any = "";
            //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }
            //------------------------------------------------------------------

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                
                token = value;
            }



            fetch("https://peetsunbae.com/dashboard/home/start", {
                method: "GET",
                credentials: "include",
                headers: { 'Authorization': token }
            }).then((response) => {
                response.json()
                    .then((result) => {
                        
                        if (result.message === "LOGIN") {
                            setUser({
                                name: result.name,
                                value: result.value,
                                id: result.id,
                                academy: result.academy,
                                location : result.location,
                                job : result.job,
                                todoistApiKey : result.todoistApiKey
                            });

                            //result.email에 dionfood가 들어가있으면 dionfood 유저로 판단
                            if(result.email.includes("dionfood")){
                                setIsDionFoodUser(true);
                                // /dashboard/restaurant으로 이동
                                history.push("/dashboard/restaurant");
                            }
                        }
                        if (result.message === "NOT_LOGIN") {
                            props.history.push("/");
                        }
                        
                    })
            }).catch(error => {
                
            })
        }

        start();
    }, []);
    //-----------------------------------------------------------------------

    //push 등록---------------------------------------------------------------

    //push 등록----------------------------------------------------------------------------

    useEffect(() => {
        //web인 상태에서 푸시 메세지
        if (!Capacitor.isNativePlatform()) {
            

            if (!navigator) {
                
                return;
            }

            if (!navigator.serviceWorker) {
                
                return;
            }

            

            const messaging = getMessaging(app);

            getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then(async (currentToken) => {
                if (currentToken) {
                    
                    

                    var userIdToken: any = "";

                    if (Capacitor.isNativePlatform()) {
                        const { value } = await Preferences.get({ key: "token" });
                        
                        userIdToken = value;
                    }

                    fetch("https://peetsunbae.com/push/registration", {
                        method: "post",
                        headers: {
                            "content-type": "application/json",
                            "Authorization": userIdToken
                        },
                        credentials: "include",
                        body: JSON.stringify({
                            token: currentToken
                        })
                    }).then((response: any) => {
                        response.json()
                            .then((result: any) => {
                                
                            })
                    })

                    //   onMessage(messaging, (payload) => {
                    //     
                    //     
                    //   })

                } else {
                    
                }
            }).catch((err) => {
                
            })

        }
    }, [navigator.serviceWorker]);

    useEffect(() => {
        nativePushRegistartion(history, presentAlert);
    }, [])

    //-------------------------------------------------------------------------------


    //-----------------------------------------------------------------------

    //로그아웃 시키는 기능-----------------------------------------------------
    const logOut = async () => {
        //일렉트론 이라면 저장된 토큰 삭제-----------------------------------------------------
        if (window.electron) {
            const result = await window.electron.sendMessageApi.setToken("logOut");
            
        }

        if (Capacitor.isNativePlatform()) {
            await Preferences.set({
                key: "token",
                value: "logOut"
            })
        }

        // ipcRenderer.send("signOut");

        //-----------------------------------------------------------------------

        //쿠키 삭제 위해 서버로 fetch 보냄-----------------------------------------
        fetch("https://peetsunbae.com/login/logout", {
            method: "GET",
            credentials: "include"
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    props.history.push({
                        pathname: "/",
                        state: { from: "dashboard" }
                    });
                })
        }).catch((err) => {
            
        })
        //--------------------------------------------------------------------------

        //-------------------------------------------------------------------
    }
    //-----------------------------------------------------------------------

    //dashboard 처음 진입 시 외출 중인지 내원 중인지 가져오는 기능-------------------------------
    useEffect(() => {


        async function start() {
            var token: any = "";
            //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }
            //------------------------------------------------------------------

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                
                token = value;
            }

            fetch("https://peetsunbae.com/dashboard/where", {
                method: "GET",
                credentials: "include",
                headers: { 'Authorization': token }
            }).then((response) => {
                response.json()
                    .then((result) => {
                        
                        if (result.message === "success") {

                            
                            
                            if (result.location === "out") {
                                setLocation("out");
                            }
                            if (result.location === "in") {
                                setLocation("in");
                            }

                        } else {

                        }

                    })
            }).catch(error => {
                
            })
        }

        start();
    }, []);
    //-----------------------------------------------------------------------------------------


    //소켓이 자기 방 알려주는 곳-----------------------------------------------------------------
    useEffect(() => {
        if (user && socket) {
            socket.emit("myRoom", user.id);
            
            if (user.value === "teacher" || user.value === "staff") {
                
                socket.emit("teacherRoom");
            }

        }

    }, [user, socket]);


    //서버 껏다 켰을때 소켓이 방 다시 알려주는 기능-----------------------------
    useEffect(() => {
        if (!user) {
            return;
        }

        const sendMyRoom = () => {
            if (user) {
                socket.emit("myRoom", user.id);
                
                if (user.value === "teacher" || user.value === "staff") {
                    
                    socket.emit("teacherRoom");
                }
            }
        }

        socket.on("connect", () => {
            sendMyRoom();
        });

    }, [user]);

    //---------------------------------------------------------------------

    //---------------------------------------------------------------------------------------------

    //socket이 실시간 업데이트 시행하는 곳----------------------------------------------------------
    useEffect(() => {


        socket.on("newTodo", () => {

            
            undoTodos();

        })


        //-----//새로운 공지사항 왔을때 공지사항 업데이트 하는 기능---------------------------
        socket.on("newNotification", () => {
            const randomNumber = Math.floor(Math.random() * (99999 - 10000) + 10000);
            setNewNotification(randomNumber);
        })
        //-----//--------------------------------------------------------------------------

        //-----//유저 위치 변경 됬을 때 업데이트 하는 기능-------------------------------

        socket.on("newLocation", (direction: string) => {
            
            if (direction === "inside") {
                setLocation("out");
            }
            if (direction === "outside") {
                setLocation("in");
            }
        });
        //-----//---------------------------------------------------------------------

        //-----------------------------------------------------------------------------

        //새로운 답변 달렸을 때 알림 받는 기능--------------------------------------------
        socket.on("newAnswer", (answerUserName) => {
            
            
            new window.Notification("답변이 달렸습니다.", { body: `${answerUserName}님이 답변을 다셨습니다.` });
        })

        //--------------------------------------------------------------------------------

        //새로운 메세지 받았을 때
        socket.on("newMessage", (messageUserName) => {
            
            
            unreadMessage();
            new window.Notification("새로운 메세지가 왔습니다.", { body: `${messageUserName}님이 메세지를 보냈습니다.` });
        })

        return function cleanup() { socket.off("newAnswer"); }

    }, []);
    //--------------------------------------------------------------------------------------------

    useEffect(() => {

        socket.on("newChatMessage", () => {
            

            // new window.Notification("새로운 메세지가 왔습니다.", {body : `메세지를 확인해주세요.`});

            unreadMessage();
            setMessageUpdate(Math.random());
        })

        return function cleanup() { socket.off("newChatMessage"); }

    }, []);


    useEffect(() => {

        if (!user) {
            return;
        }

        if (user.value === "teacher" || user.value === "staff") {

            socket.on("studentReadMessage", () => {
                

                // new window.Notification("새로운 메세지가 왔습니다.", {body : `메세지를 확인해주세요.`});

                unreadMessage();
                setMessageUpdate(Math.random());
            })

            return function cleanup() { socket.off("newChatMessage"); }

        }

    }, [user]);

    useEffect(() => {

        if(!socket){
            return;
        }

        socket.on("newFamilyMessage", () => {

            

            unreadMessage();
            setMessageUpdate(Math.random());

            const newFamilyMessageUpdate = {
                number : Math.random(),
                isUpdateNeeded : true
            }

            setFamilyMessageUpdate({...newFamilyMessageUpdate});

        })

        socket.on("familyReadMessage", () => {

            

            unreadMessage();
            setMessageUpdate(Math.random());

            const newFamilyMessageUpdate = {
                number : Math.random(),
                isUpdateNeeded : false
            }

            setFamilyMessageUpdate({...newFamilyMessageUpdate});

        })

        return function cleanup() { socket.off("familyReadMessage"); socket.off("newFamilyMessage"); }

    }, [socket]);

    useEffect(() => {

        if(!socket){
            return;
        }

        socket.on("newOpinion", () => {

            

            getOpinionNumber();

        })

    }, [socket]);


    //활성화된 메뉴에 따라 메뉴 하이라이트 되는 기능-------------------------------------------------
    const activateMenuList = (current: currentSideBarMenuList) => {
        setCurrentSideBarMenuList(current);
    }
    //----------------------------------------------------------------------------------------


    //아바타 그림 소스 가져오기
    useEffect(() => {

        const getAvatar = async () => {
            const avatarSrc: any = await getAvatarSrc();
            
            
            setAvatarSrc(avatarSrc);
        }

        getAvatar();

    }, []);
    //--------------------------------


    return (

            <main className={styles.main}>
                <div className={`${styles.appBar} ${styles.notablet}`}>
                    <div className={styles.logoDiv}>

                        {/* <LogoSvg className={styles.logoSvg} /> */}
                        {
                            (user && user.academy) &&
                            renderLogoSvg(user.academy, "white", styles.logoSvg)
                        }
                    </div>
                    <div className={styles.profileDiv}>
                        {/* <Link to="/dashboard/profile">
                            <div className={styles.profileConfig}>
                                프로필설정
                            </div>
                        </Link> */}
                        <div className={styles.avatarCircle} onClick={logOut}>
                            <img className={styles.avatar} src="img/avatarG.svg" alt="avatar"></img>
                        </div>
                        <div className={styles.logout} onClick={logOut}>
                            로그아웃
                        </div>
                    </div>
                </div>

                <div className={styles.body}>
                    <div className={`${styles.sideMenu} ${styles.notablet}`}>
                        <div className={styles.sideMenuProfile}>
                            <div className={styles.where}>
                                {
                                    location === "in" &&
                                    <>
                                        <img className={styles.inside} src="img/inside.svg" alt="outside"></img>
                                        <div className={styles.outsideText}>공부중</div>
                                    </>
                                }
                                {
                                    location === "out" &&
                                    <>
                                        <img className={styles.outside} src="img/outside.svg" alt="outside"></img>
                                        <div className={styles.outsideText}>외출중</div>
                                    </>
                                }

                            </div>
                            {
                                (!avatarSrc && user && user.academy) &&
                                <div className={styles.sideMenuAvatar}>
                                    {/* <ShapeLogoSvg className={styles.logo2} /> */}
                                    {
                                        renderLogoSvg(user.academy, "shape", styles.logo2)
                                    }
                                </div>
                            }
                            {
                                avatarSrc &&
                                <IonAvatar className={styles.ionAvatar}>
                                    <img src={avatarSrc} className={styles.ionAvatarImg} />
                                </IonAvatar>
                            }

                            <div className={styles.sideMenuName}>
                                {(user && user.academy) && englishAcademyNameToKorean(user.academy)}<span> </span>
                                {user && user.name}
                            </div>
                        </div>
                        <div className={styles.sideMenuListDiv}>
                            <ul className={styles.sideMenuList}>
                                {user &&
                                    menulist.map((each) => {


                                        if(isDionFoodUser){
                                            //home이랑 restaurant만 보여주기
                                            if(each.name !== "restaurant"){
                                                return;
                                            }
                                        }

                                        if (each.value.includes(user.value) && user.academy && each.academy.includes(user.academy)) {

                                            const needAuthorizeNeedAcademyList = returnNeedAuthorizeAcademy();

                                            if(each.job && needAuthorizeNeedAcademyList.includes(user.academy)){
                                                const preUserJob = user.job;
                                                //userJob에 뒤에 붙어있는 숫자 제거

                                                if(!preUserJob){
                                                    return;
                                                }

                                                const userJob = preUserJob.replace(/[0-9]/g, "");

                                                var isIncluded = false;

                                                each.job.includes(userJob) ? isIncluded = true : isIncluded = false;

                                                if(!isIncluded){
                                                    return;
                                                }
                                            }


                                            if (each.name !== currentSideBarMenuList) {
                                                return (
                                                    
                                                    <Link key={each.name} to={"/dashboard/" + each.name}>
                                                        <li className={styles.sideMenuListSection}>
                                                            <div className={styles.menuimgcontainer}>
                                                                <img src={"img/off/" + each.name + ".svg"} alt={each.name} className={`${styles.sideMenuListImg} ${each.name === "diagnosis" ? styles.diagnosisPicture : ""}`}></img>
                                                            </div>
                                                            <div className={styles.sideMenuListText}>
                                                                {each.description}
                                                            </div>
                                                            <div className={`${styles.sideMenuListText} ${styles.mobile}`}>

                                                                {
                                                                    each.description === "지각/결석 사유 제출" ? "사유 제출" : ""
                                                                }
                                                                {
                                                                    each.description === "나에게 온 메시지" ? "메시지" : ""
                                                                }
                                                                {
                                                                    each.description === "상담일지 적기" ? "일지 적기" : ""
                                                                }
                                                                {
                                                                    (each.description != "지각/결석 사유 제출" && each.description != "나에게 온 메시지" && each.description != "상담일지 적기") ? each.description : ""
                                                                }
                                                            </div>
                                                            {
                                                                (each.name === "envelope" && count > 0) ?
                                                                    <div className={styles.noticeNumber}>{count} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "heart" && familyCount > 0) ?
                                                                    <div className={styles.noticeNumber}>{familyCount} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "notification" && notificationCount > 0) ?
                                                                    <div className={styles.noticeNumber}>{notificationCount} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "check" && undoTodoCount > 0) ?
                                                                    <div className={styles.noticeNumber}>{undoTodoCount} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "search" && opinionCount > 0 && user && (user.value === "teacher" || user.value === "staff")) ?
                                                                    <div className={styles.noticeNumber}>{opinionCount} +</div> : ""
                                                            }
                                                        </li>
                                                    </Link>
                                                )
                                            } else {
                                                return (
                                                    <Link key={each.name} to={"/dashboard/" + each.name}>
                                                        <li className={styles.sideMenuListSection}>
                                                            <div className={styles.menuimgcontainer}>
                                                                <img src={"img/on/" + each.name + ".svg"} alt={each.name} className={`${styles.sideMenuListImg} ${each.name === "diagnosis" ? styles.diagnosisPicture : ""}`}></img>
                                                            </div>
                                                            <div className={styles.sideMenuListTextActive}>
                                                                {each.description}
                                                            </div>
                                                            <div className={`${styles.sideMenuListTextActive} ${styles.mobile}`}>

                                                                {
                                                                    each.description === "지각/결석 사유 제출" ? "사유 제출" : ""
                                                                }
                                                                {
                                                                    each.description === "나에게 온 메시지" ? "메시지" : ""
                                                                }
                                                                {
                                                                    each.description === "상담일지 적기" ? "일지 적기" : ""
                                                                }
                                                                {
                                                                    (each.description != "지각/결석 사유 제출" && each.description != "나에게 온 메시지" && each.description != "상담일지 적기") ? each.description : ""
                                                                }
                                                            </div>
                                                            {
                                                                (each.name === "envelope" && count > 0) ?
                                                                    <div className={styles.noticeNumber}>{count} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "heart" && familyCount > 0) ?
                                                                    <div className={styles.noticeNumber}>{familyCount} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "notification" && notificationCount > 0) ?
                                                                    <div className={styles.noticeNumber}>{notificationCount} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "check" && undoTodoCount > 0) ?
                                                                    <div className={styles.noticeNumber}>{undoTodoCount} +</div> : ""
                                                            }
                                                            {
                                                                (each.name === "search" && opinionCount > 0 && user && (user.value === "teacher" || user.value === "staff")) ?
                                                                    <div className={styles.noticeNumber}>{opinionCount} +</div> : ""
                                                            }
                                                        </li>
                                                    </Link>
                                                )
                                            }
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <Switch>
                        <>
                        <Route exact path="/dashboard/" render={(props) => <Home activateMenuList={activateMenuList} socket={socket} newNotification={newNotification} {...props} />} />
                        <Route path="/dashboard/home" render={(props) => <Home activateMenuList={activateMenuList} socket={socket} newNotification={newNotification} {...props} />} />
                        <Route path="/dashboard/alarm" render={(props) => <Alarm activateMenuList={activateMenuList} user={user} {...props} />} />
                        <Route path="/dashboard/text" render={(props) => <Text activateMenuList={activateMenuList} user={user} {...props} />} />
                        <Route path="/dashboard/attendance" render={(props) => <Attendance socket={socket} user={user} activateMenuList={activateMenuList} messageUpdate={messageUpdate} unreadMessage={unreadMessage} {...props} />} />
                        <Route path="/dashboard/avatar" render={(props) => <Avatar activateMenuList={activateMenuList} socket={socket} user={user} {...props} />} />
                        <Route path="/dashboard/book" render={(props) => <Book activateMenuList={activateMenuList} user={user} {...props} />} />
                        <Route path="/dashboard/chart" render={(props) => <Chart activateMenuList={activateMenuList} user={user} socket={socket} {...props} />} />
                        <Route path="/dashboard/edit" render={(props) => <Edit user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route path="/dashboard/envelope" render={(props) => <Envelope messageUpdate={messageUpdate} unreadMessage={unreadMessage} socket={socket} user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route exact path="/dashboard/notification" render={(props) => <Notification user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route exact path="/dashboard/question" render={(props) => <Question user={user} activateMenuList={activateMenuList} {...props} socket={socket} />} />
                        <Route path="/dashboard/report" render={(props) => <Report activateMenuList={activateMenuList} socket={socket} {...props} />} />
                        <Route path="/dashboard/restaurant" render={(props) => <Restaurant isDionFoodUser={isDionFoodUser} user={user} activateMenuList={activateMenuList} socket={socket} {...props} />} />
                        <Route path="/dashboard/search" render={(props) => <Search activateMenuList={activateMenuList} user={user} socket={socket} {...props} />} />
                        <Route path="/dashboard/notification/write" render={(props) => <NotificationWrite activateMenuList={activateMenuList} socket={socket} user={user} {...props} />} />
                        <Route path="/dashboard/question/write" render={(props) => <QuestionWrite activateMenuList={activateMenuList} socket={socket} {...props} />} />
                        <Route path="/dashboard/profile" render={(props) => <Profile {...props} />} />
                        <Route path="/dashboard/notificationdetail" render={(props) => <NotificationDetail user={user} handleReadNotification={handleReadNotification} {...props} />} />
                        <Route path="/dashboard/heart" render={(props) => <Heart user={user} socket={socket} activateMenuList={activateMenuList} unreadMessage={unreadMessage} familyMessageUpdate={familyMessageUpdate} {...props} />} />
                        <Route path="/dashboard/diagnosis" render={(props) => <Diagnosis user={user} activateMenuList={activateMenuList} />} />
                        <Route path="/dashboard/check" render={(props) => <Check user={user} activateMenuList={activateMenuList} undoTodos={undoTodos} socket={socket} {...props} />} />
                        <Route path="/dashboard/seatpractice" render={(props) => <SeatPractice {...props} />} />
                        <Route path="/dashboard/wifipermit" render={(props) => <WifiPermit user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route path="/dashboard/wifipermitregister" render={(props) => <WifipermitForPc user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route path="/dashboard/iot" render={(props) => <Iot user={user} activateMenuList={activateMenuList} {...props} />}   />
                        <Route path="/dashboard/checklist" render={(props) => <Checklist user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route path="/dashboard/money" render={(props) => <Money user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route path="/dashboard/booking" render={(props) => <Booking user={user} activateMenuList={activateMenuList} {...props} />} />
                        <Route path="/dashboard/circle-check" render={(props) => <NewTodoMain user={user} activateMenuList={activateMenuList} {...props} />} />
                        </>
                    </Switch>
                </div>

            </main>


    )
}

export default Dashboard;
