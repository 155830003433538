import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import InnerHeader from "../etc/innerheader";
import styles from "./mobilerestaurant.module.css";
import MobileRestaurantStatus from "./mobilerestaurantstatus";
import MobileRestaurantSubmit from "./mobilerestaurantsubmit";

import { IonApp } from "@ionic/react";
import MenuBar from "../etc/\bmovingUnderbarMenu";
import { getFetch } from "../../fetch/fetch";
import CoinModal from "./modal/coinmodal";

import { IonToast } from '@ionic/react';
import MobileRestaurantSubmit2 from "./mobilerestaurantsubmit2";
import MealTicketModal from "./modal/mealticketmodal";
import { returnDayString } from "../etc/functions";
import { da } from "date-fns/locale";

export type MenuItem = {
    key: string;
    label: string;
};

const menuItems1: MenuItem[] = [
    { key: "submit", label: "도시락 신청" },
    { key: "status", label: "신청 현황" },
];

const menuItems2: MenuItem[] = [
    { key: "submit", label: "특식 신청" },
    { key: "submit2", label: "정기식 신청" },
    { key: "status", label: "신청 현황" },
];


const MobileRestaurant: React.FC<any> = (props) => {

    const [currentMenu, setCurrentMenu] = useState("submit");
    const [underBarPosition, setUnderBarPosition] = useState(6);

    const topDivRef = useRef<any>(null);
    const [statusDivHeight, setStatusDivHeight] = useState(0);
    const history = useHistory();

    const [chargedMoney, setChargedMoney] = useState(0);
    const [chargedMealTicket, setChargedMealTicket] = useState(0);

    const [availableCharge, setAvailableCharge] = useState(0);
    const [availableChargeString, setAvailableChargeString] = useState("");

    const [isCoinModalOpen, setIsCoinModalOpen] = useState(false);
    const [isMealTicketModalOpen, setIsMealTicketModalOpen] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [showToast2, setShowToast2] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastMessage2, setToastMessage2] = useState("");

    const [update, setUpdate] = useState(0);

    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);

    const [firstWeekInfo, setFirstWeekInfo] = useState<any>();
    const [secondWeekInfo, setSecondWeekInfo] = useState<any>();

    const [targetDateIndex, setTargetDateIndex] = useState<any>(0);

    const [whenType, setWhenType] = useState<any>("lunch");
    const whiteRef = useRef<any>(null);

    const wrapperRef = useRef<any>(null);
    const [translateValue, setTranslateValue] = useState("translate(3%, -109%)");

    const dateSelectBarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        if(!dateSelectBarRef){
            return;
        }

        if(!dateSelectBarRef.current) {
            return;
        }

        dateSelectBarRef.current.scrollLeft = 0;

    }, [dateSelectBarRef.current]);


    useEffect(() => {

        if (!props.socket) {
            return;
        }

        props.socket.on("newAmount", () => {

            letsUpdate();
        })

        return function cleanup() { props.socket.off("newAmount"); }

    }, [props.socket]);

    useEffect(() => {



        const handleBackButton = () => {

            if (currentMenu === "status") {
                return;
            }


            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, [currentMenu]);


    useEffect(() => {

        if (!topDivRef || !topDivRef.current) {
            return;
        }

        const calculateStatusDivHeight = () => {

            setTimeout(() => {

                if (!topDivRef || !topDivRef.current) {
                    return;
                }

                const totalHeight = window.innerHeight;
                const topDivHeight = topDivRef.current.offsetHeight;

                const statusDivHeight = totalHeight - topDivHeight;




                setStatusDivHeight(statusDivHeight);

            }, 20);

        }

        calculateStatusDivHeight();

        window.addEventListener("resize", () => calculateStatusDivHeight());

        return () => window.removeEventListener("resize", () => calculateStatusDivHeight());

    }, [topDivRef]);

    useEffect(() => {
        const newDate = new Date();
        const newFirstWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};

            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            if (i === 0) {
                oneRow.today = true;
            }
            newFirstWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        setFirstWeekInfo([...newFirstWeekInfo]);

        const newSecondWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};
            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            newSecondWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        setSecondWeekInfo([...newSecondWeekInfo]);

    }, []);

    const handleClickDate = (index: number) => {
        setTargetDateIndex(index);
    }


    const handleCurrentMenu = (menu: string) => {
        setCurrentMenu(menu);
    }

    const letsUpdate = () => {
        setUpdate(Math.random());
    }

    useEffect(() => {

        getCoin();

    }, [update]);

    const getCoin = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getcoin");

            if (result.message === "success") {

                setChargedMoney(result.chargedMoney);
                setChargedMealTicket(result.chargedMealTicket);
                setAvailableCharge(100000 - result.chargedMoney);
                setAvailableChargeString(`최대 보유 금액 100,000원. 현재 충전 가능액 ${(100000 - result.chargedMoney).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`);

            }

        } catch (e) {
        }

    }

    const handleClose = () => {
        setIsCoinModalOpen(false);
    }

    const handleClose2 = () => {
        setIsMealTicketModalOpen(false);
    }

    const handleToastMessage = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
    }

    const handleToastMessage2 = (message: string) => {
        setToastMessage2(message);
        setShowToast2(true);
    }

    const handleCoinModalOpen = () => {
        setIsCoinModalOpen(true);
    }

    const handleMealTicketModalOpen = () => {
        setIsMealTicketModalOpen(true);
    }

    useEffect(() => {

        getPhoneNumbers();

    }, []);

    const getPhoneNumbers = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getphonenumbers");


            const phoneNumbers: string[] = [];

            if (result.myPhoneNumber) {
                phoneNumbers.push(result.myPhoneNumber);
            }

            if (result.parentPhoneNumbers) {
                //동일한게 있으면 추가하지 않음
                result.parentPhoneNumbers.forEach((item: any) => {
                    if (phoneNumbers.indexOf(item) === -1) {
                        phoneNumbers.push(item);
                    }
                });
            }


            setPhoneNumbers([...phoneNumbers]);

        } catch (e) {
        }

    }

    const menuClickHandle = (index: number) => {



        if (!wrapperRef.current || !whiteRef.current) {

            return;
        }

        const wrapperWidth = wrapperRef.current.clientWidth;
        const whiteWidth = whiteRef.current.clientWidth;

        const value0 = whiteWidth * 0.03;
        const value2 = wrapperWidth - whiteWidth * 1.03;

        var newTranslateValue: any;
        switch (index) {
            case 0:
                newTranslateValue = value0;
                setWhenType("lunch");
                break;
            case 1:
                newTranslateValue = value2;
                setWhenType("dinner");
                break;
        }

        if (!newTranslateValue) {
            return;
        }

        const newTranslateValueText = `translate(${newTranslateValue}px, -109%)`;

        setTranslateValue(newTranslateValueText);
    }


    return (
        <IonApp>
            <div>
                <div ref={topDivRef}>
                    <InnerHeader title={"도시락 신청"}
                        isRestaurantSubmitPage={currentMenu === "submit" || currentMenu === "submit2"}
                        isCoin={currentMenu === "submit"}
                        chargedMoney={chargedMoney}
                        chargedMealTicket={chargedMealTicket}
                        handleClickRestaurantSubmit={() => {
                            if (currentMenu === "submit") {
                                handleCoinModalOpen();
                            }
                            if (currentMenu === "submit2") {
                                handleMealTicketModalOpen();
                            }
                        }}
                    />
                    <div className={styles.underBorder}>

                    </div>

                    {/* <div className={styles.menuBar}>
                        <div className={`${styles.menuBarSection} ${currentMenu === "submit" ? styles.active : ""}`} onClick={() => { moveUnderBar(1); setCurrentMenu("submit"); }}>
                            도시락 신청
                        </div>
                        <div className={`${styles.menuBarStatus} ${currentMenu === "status" ? styles.active : ""}`} onClick={() => { moveUnderBar(2); setCurrentMenu("status"); }}>
                            신청 현황
                        </div>
                        <div className={styles.menuUnderBar} style={{ left: `${underBarPosition}%` }}>

                        </div>
                    </div> */}
                    <MenuBar menuItems={props.isNeedRegularMeal ? menuItems2 : menuItems1} handleCurrentMenu={handleCurrentMenu} />
                </div>

                {
                    currentMenu === "submit" || currentMenu === "submit2" ?
                        <>
                            <div className={styles.dateSelectBarDiv}>
                                <div className={styles.dateSelectBar}
                                ref={dateSelectBarRef}
                                >
                                    <div className={styles.weekDiv}>
                                        {
                                            firstWeekInfo && firstWeekInfo.map((eachDate: any, index: number) => {
                                                return (
                                                    <div key={index} className={`${styles.eachDayDiv} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""} ${(index === targetDateIndex) ? styles.active : ""}`} onClick={(e: any) => { handleClickDate(index); }}>
                                                        <div className={styles.eachDayDivWrapper}>
                                                            <div className={`${styles.dateNumber} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                                {eachDate.date}
                                                            </div>
                                                            <div className={`${styles.dayString} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                                {eachDate.today ? "오늘" : eachDate.dayString}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className={styles.weekDiv}>
                                        {
                                            secondWeekInfo && secondWeekInfo.map((eachDate: any, index: number) => {
                                                return (
                                                    <div key={index} className={`${styles.eachDayDiv} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""} ${(index + 7 === targetDateIndex) ? styles.active : ""}`} onClick={(e: any) => { handleClickDate(index + 7); }}>
                                                        <div className={styles.eachDayDivWrapper}>
                                                            <div className={`${styles.dateNumber} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                                {eachDate.date}
                                                            </div>
                                                            <div className={`${styles.dayString} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                                {eachDate.today ? "오늘" : eachDate.dayString}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={styles.justBorder}>

                            </div>

                            <div style={{ marginTop: "1.5rem" }}>

                            </div>

                            <div className={styles.kindSelectDiv} ref={wrapperRef}>
                                <div className={styles.studyTimeSelectMenuWrapper}>
                                    <div className={`${styles.studyTimeSelectMenuText} ${whenType === "lunch" ? styles.active : ""}`} onClick={() => { menuClickHandle(0) }}>
                                        점심
                                    </div>
                                    <div className={`${styles.studyTimeSelectMenuText} ${whenType === "dinner" ? styles.active : ""}`} onClick={() => { menuClickHandle(1) }}>
                                        저녁
                                    </div>
                                </div>
                                <div style={{ position: "relative" }}>
                                    <div ref={whiteRef} className={styles.whiteWrapper} style={{ transform: translateValue }}>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }

                {
                    currentMenu === "submit" &&
                    <MobileRestaurantSubmit
                        letsUpdate={letsUpdate}
                        targetDateIndex={targetDateIndex}
                        whenType={whenType}
                    />
                }
                {
                    currentMenu === "submit2" &&
                    <MobileRestaurantSubmit2
                        letsUpdate={letsUpdate}
                        chargedMealTicket={chargedMealTicket}
                        targetDateIndex={targetDateIndex}
                        whenType={whenType}
                    />
                }
                {
                    currentMenu === "status" &&
                    <MobileRestaurantStatus
                        socket={props.socket}
                        statusDivHeight={statusDivHeight}
                        chargedMoney={chargedMoney}
                        chargedMealTicket={chargedMealTicket}
                        handleCoinModalOpen={handleCoinModalOpen}
                        handleMealTicketModalOpen={handleMealTicketModalOpen}
                        handleToastMessage={handleToastMessage}
                        handleToastMessage2={handleToastMessage2}
                        letsUpdate={letsUpdate}
                        isNeedRegularMeal={props.isNeedRegularMeal}
                    />
                }

                <CoinModal
                    isOpen={isCoinModalOpen}
                    handleClose={handleClose}
                    availableCharge={availableCharge}
                    availableChargeString={availableChargeString}
                    handleToastMessage={handleToastMessage}
                />

                <MealTicketModal
                    isOpen={isMealTicketModalOpen}
                    handleClose={handleClose2}
                    handleToastMessage={handleToastMessage}
                    phoneNumbers={phoneNumbers}
                />

                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                    message={toastMessage}
                    duration={1500}
                    position="top"
                    color="danger"
                />

                <IonToast
                    isOpen={showToast2}
                    onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                    message={toastMessage2}
                    duration={1500}
                    position="top"
                    color="primary"
                />

            </div>
        </IonApp>
    );
}

export default MobileRestaurant;