import React, { useState, useRef, useEffect } from "react";
import styles from "./movingUnderbarMenu.module.css";
import { MenuItem } from "../restaurant/mobilerestaurant";



export default function MenuBar(props : any) {
  // 실제 메뉴 아이템 리스트 (원하는 대로 늘리거나 줄여도 OK)
//   const menuItems: MenuItem[] = [
//     { key: "submit", label: "도시락 신청" },
//     { key: "status", label: "신청 현황" },
//     { key: "extra", label: "추가 메뉴" },
//   ];

   const menuItems: MenuItem[] = props.menuItems;
    const handleCurrentMenu = props.handleCurrentMenu;

  const [currentMenu, setCurrentMenu] = useState(menuItems[0].key);

  // UnderBar 스타일(left, width)을 저장할 state
  const [underBarStyle, setUnderBarStyle] = useState({ left: 0, width: 0 });

  // 각 메뉴 div에 대한 ref를 담을 배열
  const menuRefs = useRef<Array<HTMLDivElement | null>>([]);

  // 메뉴 개수가 변할 때마다 refs 배열의 길이 조정 (불필요하면 생략 가능)
  useEffect(() => {
    menuRefs.current = menuRefs.current.slice(0, menuItems.length);
  }, [menuItems.length]);

  //새로고침 했을때 메뉴바 위치 초기화
  useEffect(() => {

    if(menuRefs && menuRefs.current){
        const index = menuItems.findIndex((item) => item.key === currentMenu);
        moveUnderBar(index);
    }


  }, [menuRefs.current]);

  // UnderBar 이동 함수
  const moveUnderBar = (index: number) => {
    const target = menuRefs.current[index];
    if (target) {
      const { offsetLeft, offsetWidth } = target;

      
      const width = offsetWidth * 0.85;
      const left = offsetLeft + (offsetWidth - width) / 2;

      setUnderBarStyle({ left: left, width: width });
    }
  };

  // 첫 렌더 혹은 currentMenu가 바뀔 때도 자동으로 UnderBar 위치 세팅(초기 위치 잡기용)
  useEffect(() => {
    const index = menuItems.findIndex((item) => item.key === currentMenu);
    if (index !== -1) {
      moveUnderBar(index);
      handleCurrentMenu(currentMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu]);

  return (
    <div className={styles.menuBar}>
      {menuItems.map((menuItem, index) => (
        <div
          key={menuItem.key}
          // ref 설정
          ref={(el) => (menuRefs.current[index] = el)}
          // 현재 메뉴라면 active 클래스를 주는 식
          className={
            menuItem.key === currentMenu
              ? `${styles.menuBarSection} ${styles.active}`
              : styles.menuBarSection
          }
          onClick={() => {
            setCurrentMenu(menuItem.key);
            moveUnderBar(index);
          }}
        >
          {menuItem.label}
        </div>
      ))}

      {/* UnderBar: left, width를 state로 관리하고 style에 반영 */}
      <div
        className={styles.menuUnderBar}
        style={{ left: underBarStyle.left, width: underBarStyle.width }}
      />
    </div>
  );
}