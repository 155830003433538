import * as React from "react";

const SongdoFixedSeatSvg2 = (props : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="1597"
    // height="1133"
    // viewBox="0 0 1597 1133"
    width="100%"
    viewBox="230 0 1200 1500"
  >
    <g id="page7" transform="translate(5.5 5.5)">
      <path fill="none" d="M0 0h1587v1123H0z"></path>
      <g id="shape1">
        <path
          fill="#7e7e7e"
          d="M245.921 91.011v966.2l3.8-3.8 3.8-3.7v-951.1l-3.8-3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M245.921 91.011v966.2m7.6-7.5v-951.1"
        ></path>
      </g>
      <g id="shape2">
        <path
          fill="#7e7e7e"
          d="M1354.423 91.031h-1108.5l3.8 3.8 3.8 3.8h1093.3l3.8-3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M1354.423 91.031h-1108.5m7.6 7.6h1093.3"
        ></path>
      </g>
      <g id="shape3">
        <path
          fill="#7e7e7e"
          d="M1346.933 1049.67h-1093.5l-3.7 3.8-3.8 3.8h1108.6l-3.8-3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="M1346.933 1049.67h-1093.5m-7.5 7.6h1108.6"
        ></path>
      </g>
      <g id="shape4">
        <path
          fill="#fff"
          d="M689.908 1049.67h-52.9v7.6h52.9zm0 0v7.6h4v-7.6zm-52.9 0v7.6h-4v-7.6zm0 0v-52.9h4v52.9z"
        ></path>
        <path
          fill="none"
          stroke="#303030"
          d="M689.908 1049.67c0-29.2-23.7-52.9-52.9-52.9m52.9 52.9v7.6h4v-7.6zm-52.9 0v7.6h-4v-7.6zm0 0v-52.9h4v52.9z"
        ></path>
      </g>
      <g id="shape5">
        <path
          fill="#fff"
          stroke="#323232"
          d="M617.91 1057.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(617.91 1057.28)"
        >
          <tspan x="34.3" y="19">
            입구
          </tspan>
        </text>
      </g>
      <g id="430">
        <path
          fill="#d7d7d7"
          stroke="#323232"
          d="M436.599 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(436.599 124.29)"
        >
          <tspan x="37" y="19">
            43
          </tspan>
        </text>
      </g>
      <g id="431">
        <path
          fill="#fff"
          stroke="#323232"
          d="M436.599 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(436.599 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="432">
        <path
          fill="#fff"
          stroke="#323232"
          d="M436.599 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(436.599 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="500">
        <path
          fill="#d7d7d7"
          stroke="#323232"
          d="M254.43 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(254.43 124.29)"
        >
          <tspan x="37" y="19">
            50
          </tspan>
        </text>
      </g>
      <g id="501">
        <path
          fill="#fff"
          stroke="#323232"
          d="M254.43 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(254.43 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="502">
        <path
          fill="#fff"
          stroke="#323232"
          d="M254.43 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(254.43 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="360">
        <path
          fill="#d7d7d7"
          stroke="#323232"
          d="M527.685 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(527.685 124.29)"
        >
          <tspan x="37" y="19">
            36
          </tspan>
        </text>
      </g>
      <g id="361">
        <path
          fill="#fff"
          stroke="#323232"
          d="M527.685 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(527.685 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="362">
        <path
          fill="#fff"
          stroke="#323232"
          d="M527.685 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(527.685 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="290">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M709.848 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(709.848 124.29)"
        >
          <tspan x="37" y="19">
            29
          </tspan>
        </text>
      </g>
      <g id="291">
        <path
          fill="#fff"
          stroke="#101843"
          d="M709.848 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(709.848 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="292">
        <path
          fill="#fff"
          stroke="#101843"
          d="M709.848 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(709.848 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="220">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M800.927 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(800.927 124.29)"
        >
          <tspan x="37" y="19">
            22
          </tspan>
        </text>
      </g>
      <g id="221">
        <path
          fill="#fff"
          stroke="#101843"
          d="M800.927 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(800.927 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="222">
        <path
          fill="#fff"
          stroke="#101843"
          d="M800.927 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(800.927 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="80">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1074.179 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1074.179 124.29)"
        >
          <tspan x="41.3" y="19">
            8
          </tspan>
        </text>
      </g>
      <g id="81">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1074.179 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1074.179 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="82">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1074.179 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1074.179 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="150">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M983.099 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(983.099 124.29)"
        >
          <tspan x="37" y="19">
            15
          </tspan>
        </text>
      </g>
      <g id="151">
        <path
          fill="#fff"
          stroke="#101843"
          d="M983.099 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(983.099 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="152">
        <path
          fill="#fff"
          stroke="#101843"
          d="M983.099 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(983.099 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="10">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.349 124.29h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.349 124.29)"
        >
          <tspan x="41.3" y="19">
            1
          </tspan>
        </text>
      </g>
      <g id="11">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.349 149.989h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.349 149.988)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="12">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.349 175.688h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.349 175.688)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="440">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M435.66 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(435.66 252.785)"
        >
          <tspan x="37" y="19">
            44
          </tspan>
        </text>
      </g>
      <g id="441">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="442">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="510">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M253.492 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(253.492 252.785)"
        >
          <tspan x="37" y="19">
            51
          </tspan>
        </text>
      </g>
      <g id="511">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="512">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="370">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M526.747 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(526.747 252.785)"
        >
          <tspan x="37" y="19">
            37
          </tspan>
        </text>
      </g>
      <g id="371">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="372">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="300">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M708.91 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(708.91 252.785)"
        >
          <tspan x="37" y="19">
            30
          </tspan>
        </text>
      </g>
      <g id="301">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="302">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="230">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M799.989 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(799.989 252.785)"
        >
          <tspan x="37" y="19">
            23
          </tspan>
        </text>
      </g>
      <g id="231">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="232">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="90">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1073.239 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1073.239 252.785)"
        >
          <tspan x="41.3" y="19">
            9
          </tspan>
        </text>
      </g>
      <g id="91">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="92">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="160">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M982.159 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(982.159 252.785)"
        >
          <tspan x="37" y="19">
            16
          </tspan>
        </text>
      </g>
      <g id="161">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="162">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="20">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.409 252.785h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.409 252.785)"
        >
          <tspan x="41.3" y="19">
            2
          </tspan>
        </text>
      </g>
      <g id="21">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 278.484h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 278.483)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="22">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 304.183h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 304.183)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="450">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M435.66 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(435.66 381.28)"
        >
          <tspan x="37" y="19">
            45
          </tspan>
        </text>
      </g>
      <g id="451">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="452">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="520">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M253.492 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(253.492 381.28)"
        >
          <tspan x="37" y="19">
            52
          </tspan>
        </text>
      </g>
      <g id="521">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="522">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="380">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M526.747 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(526.747 381.28)"
        >
          <tspan x="37" y="19">
            38
          </tspan>
        </text>
      </g>
      <g id="381">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="382">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="310">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M708.91 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(708.91 381.28)"
        >
          <tspan x="37" y="19">
            31
          </tspan>
        </text>
      </g>
      <g id="311">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="312">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="240">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M799.989 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(799.989 381.28)"
        >
          <tspan x="37" y="19">
            24
          </tspan>
        </text>
      </g>
      <g id="241">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="242">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="100">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1073.239 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1073.239 381.28)"
        >
          <tspan x="37" y="19">
            10
          </tspan>
        </text>
      </g>
      <g id="101">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="102">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="170">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M982.159 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(982.159 381.28)"
        >
          <tspan x="37" y="19">
            17
          </tspan>
        </text>
      </g>
      <g id="171">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="172">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="30">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.409 381.28h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.409 381.28)"
        >
          <tspan x="41.3" y="19">
            3
          </tspan>
        </text>
      </g>
      <g id="31">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 406.979h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 406.978)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="32">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 432.678h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 432.678)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="460">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M435.66 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(435.66 509.774)"
        >
          <tspan x="37" y="19">
            46
          </tspan>
        </text>
      </g>
      <g id="461">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="462">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="530">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M253.492 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(253.492 509.774)"
        >
          <tspan x="37" y="19">
            53
          </tspan>
        </text>
      </g>
      <g id="531">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="532">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="390">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M526.747 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(526.747 509.774)"
        >
          <tspan x="37" y="19">
            39
          </tspan>
        </text>
      </g>
      <g id="391">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="392">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="320">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M708.91 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(708.91 509.774)"
        >
          <tspan x="37" y="19">
            32
          </tspan>
        </text>
      </g>
      <g id="321">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="322">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="250">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M799.989 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(799.989 509.774)"
        >
          <tspan x="37" y="19">
            25
          </tspan>
        </text>
      </g>
      <g id="251">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="252">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="110">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1073.239 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1073.239 509.774)"
        >
          <tspan x="37" y="19">
            11
          </tspan>
        </text>
      </g>
      <g id="111">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="112">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="180">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M982.159 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(982.159 509.774)"
        >
          <tspan x="37" y="19">
            18
          </tspan>
        </text>
      </g>
      <g id="181">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="182">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="40">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.409 509.775h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.409 509.774)"
        >
          <tspan x="41.3" y="19">
            4
          </tspan>
        </text>
      </g>
      <g id="41">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 535.474h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 535.473)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="42">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 561.173h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 561.173)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="470">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M435.66 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(435.66 638.27)"
        >
          <tspan x="37" y="19">
            47
          </tspan>
        </text>
      </g>
      <g id="471">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="472">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="540">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M253.492 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(253.492 638.27)"
        >
          <tspan x="37" y="19">
            54
          </tspan>
        </text>
      </g>
      <g id="541">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="542">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="400">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M526.747 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(526.747 638.27)"
        >
          <tspan x="37" y="19">
            40
          </tspan>
        </text>
      </g>
      <g id="401">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="402">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="330">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M708.91 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(708.91 638.27)"
        >
          <tspan x="37" y="19">
            33
          </tspan>
        </text>
      </g>
      <g id="331">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="332">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="260">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M799.989 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(799.989 638.27)"
        >
          <tspan x="37" y="19">
            26
          </tspan>
        </text>
      </g>
      <g id="261">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 663.968)"
        >
          <tspan x="34.3" y="19">
            이름
          </tspan>
        </text>
      </g>
      <g id="262">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="120">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1073.239 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1073.239 638.27)"
        >
          <tspan x="37" y="19">
            12
          </tspan>
        </text>
      </g>
      <g id="121">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="122">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="190">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M982.159 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(982.159 638.27)"
        >
          <tspan x="37" y="19">
            19
          </tspan>
        </text>
      </g>
      <g id="191">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="192">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="50">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.409 638.27h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.409 638.27)"
        >
          <tspan x="41.3" y="19">
            5
          </tspan>
        </text>
      </g>
      <g id="51">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 663.969h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 663.968)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="52">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 689.668h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 689.668)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="480">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M435.66 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(435.66 766.764)"
        >
          <tspan x="37" y="19">
            48
          </tspan>
        </text>
      </g>
      <g id="481">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="482">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="550">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M253.492 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(253.492 766.764)"
        >
          <tspan x="37" y="19">
            55
          </tspan>
        </text>
      </g>
      <g id="551">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="552">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="410">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M526.747 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(526.747 766.764)"
        >
          <tspan x="37" y="19">
            41
          </tspan>
        </text>
      </g>
      <g id="411">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="412">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="340">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M708.91 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(708.91 766.764)"
        >
          <tspan x="37" y="19">
            34
          </tspan>
        </text>
      </g>
      <g id="341">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="342">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="270">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M799.989 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(799.989 766.764)"
        >
          <tspan x="37" y="19">
            27
          </tspan>
        </text>
      </g>
      <g id="271">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="272">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="130">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1073.239 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1073.239 766.764)"
        >
          <tspan x="37" y="19">
            13
          </tspan>
        </text>
      </g>
      <g id="131">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="132">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="200">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M982.159 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(982.159 766.764)"
        >
          <tspan x="37" y="19">
            20
          </tspan>
        </text>
      </g>
      <g id="201">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="202">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="60">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.409 766.765h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.409 766.764)"
        >
          <tspan x="41.3" y="19">
            6
          </tspan>
        </text>
      </g>
      <g id="61">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 792.464h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 792.463)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="62">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 818.163h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 818.163)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="490">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M435.66 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(435.66 895.26)"
        >
          <tspan x="37" y="19">
            49
          </tspan>
        </text>
      </g>
      <g id="491">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="492">
        <path
          fill="#fff"
          stroke="#101843"
          d="M435.66 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(435.66 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="560">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M253.492 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(253.492 895.26)"
        >
          <tspan x="37" y="19">
            56
          </tspan>
        </text>
      </g>
      <g id="561">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="562">
        <path
          fill="#fff"
          stroke="#101843"
          d="M253.492 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(253.492 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="420">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M526.747 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(526.747 895.26)"
        >
          <tspan x="37" y="19">
            42
          </tspan>
        </text>
      </g>
      <g id="421">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="422">
        <path
          fill="#fff"
          stroke="#101843"
          d="M526.747 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(526.747 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="350">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M708.91 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(708.91 895.26)"
        >
          <tspan x="37" y="19">
            35
          </tspan>
        </text>
      </g>
      <g id="351">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="352">
        <path
          fill="#fff"
          stroke="#101843"
          d="M708.91 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(708.91 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="280">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M799.989 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(799.989 895.26)"
        >
          <tspan x="37" y="19">
            28
          </tspan>
        </text>
      </g>
      <g id="281">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="282">
        <path
          fill="#fff"
          stroke="#101843"
          d="M799.989 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(799.989 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="140">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1073.239 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1073.239 895.26)"
        >
          <tspan x="37" y="19">
            14
          </tspan>
        </text>
      </g>
      <g id="141">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="142">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1073.239 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1073.239 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="210">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M982.159 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(982.159 895.26)"
        >
          <tspan x="37" y="19">
            21
          </tspan>
        </text>
      </g>
      <g id="211">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="212">
        <path
          fill="#fff"
          stroke="#101843"
          d="M982.159 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(982.159 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="70">
        <path
          fill="#d8d8d8"
          stroke="#101843"
          d="M1255.409 895.26h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          fontWeight="bold"
          transform="translate(1255.409 895.26)"
        >
          <tspan x="41.3" y="19">
            7
          </tspan>
        </text>
      </g>
      <g id="71">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 920.959h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 920.958)"
        >
          <tspan x="34.3" y="18.8">
            이름
          </tspan>
        </text>
      </g>
      <g id="72">
        <path
          fill="#fff"
          stroke="#101843"
          d="M1255.409 946.658h91.1v25.7h-91.1z"
        ></path>
        <text
          xmlSpace="preserve"
          fill="#191919"
          fontFamily="Lucida Grande,PingFang SC,Mircrosoft YaHei,Arial"
          fontSize="17.333"
          transform="translate(1255.409 946.658)"
        >
          <tspan x="34.3" y="18.8">
            메모
          </tspan>
        </text>
      </g>
      <g id="shape174">
        <path
          fill="#7e7e7e"
          d="m1346.845 98.611.111 951.1 3.8 3.7 3.801 3.8-.113-966.2-3.8 3.8z"
        ></path>
        <path
          fill="none"
          stroke="#7e7e7e"
          d="m1346.845 98.611.111 951.1m7.601 7.5-.113-966.2"
        ></path>
      </g>
    </g>
  </svg>
);

export default SongdoFixedSeatSvg2;
