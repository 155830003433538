import React, { useEffect, useState } from "react";
import { IonLoading, IonModal } from "@ionic/react";
import InnerHeaderForModal from "../../etc/innerheaderformodal";
import styles from "../mobilerestaurantstatus.module.css";
import { Add, Remove } from "@mui/icons-material";
import { ReactComponent as Plus } from "../../../svg/circle-plus-thin.svg";
import { ReactComponent as Minus } from "../../../svg/circle-minus-thin.svg";
import { set } from "lodash";

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Person from '@mui/icons-material/Person';
import People from '@mui/icons-material/People';
import Apartment from '@mui/icons-material/Apartment';
import BottomButton from "../../etc/bottomButton";
import { postFetch } from "../../../fetch/fetch";

import { useHistory } from "react-router-dom";
import { oneMealTicketPrice } from "../../data/datas";

interface MealTicketModalProps {
    handleClose: () => void;
    isOpen: boolean;
    handleToastMessage: (message: string) => void;
    phoneNumbers: string[];
}

function formatPhoneNumber(phoneNumber: string) {
    // 숫자만 남기도록 정리 (혹시 공백, '-' 등이 섞여 들어올 수 있는 경우 대비)
    const cleaned = phoneNumber.replace(/[^\d]/g, '');

    // 11자리인 경우(일반적인 한국 휴대폰 번호) 3-4-4로 포맷팅
    if (cleaned.length === 11) {
        return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }

    // 그 외 길이의 경우 별도 처리(필요하다면 추가 로직 작성)
    return phoneNumber;
}

export function RadioPositionEnd(phoneNumbers: string[], handleSelectedPhoneNumber: (phoneNumber: string) => void) {
    return (
        <RadioGroup aria-label="Your plan" name="people"
            onChange={(e) => handleSelectedPhoneNumber(e.target.value)}
        >
            <List
                sx={{
                    minWidth: 240,
                    '--List-gap': '0.5rem',
                    '--ListItem-paddingY': '1rem',
                    '--ListItem-radius': '8px',
                    '--ListItemDecorator-size': '32px',
                }}
            >
                {phoneNumbers.map((item, index) => (
                    <ListItem variant="outlined" key={item} sx={{ boxShadow: 'sm' }}>
                        {/* <ListItemDecorator>
                {[<Person />, <People />, <Apartment />][index]}
              </ListItemDecorator> */}
                        <Radio
                            overlay
                            value={item}
                            label={<span
                            style={{fontFamily : "Apple_R"}}
                            >{formatPhoneNumber(item)}</span>}
                            sx={{ flexGrow: 1, flexDirection: 'row' }}
                            slotProps={{
                                action: ({ checked }) => ({
                                    sx: (theme) => ({
                                        ...(checked && {
                                            inset: -1,
                                            border: '2px solid',
                                            borderColor: theme.vars.palette.primary[500],
                                        }),
                                    }),
                                }),
                            }}
                        />
                    </ListItem>
                ))}
            </List>
        </RadioGroup>
    );
}

const MealTicketModal: React.FC<MealTicketModalProps> = (props) => {

    const [number, setNumber] = useState(1);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {

        setNumber(1);
        setSelectedPhoneNumber("");

    }, [props.isOpen]);

    const handleClose = () => {
        setNumber(1);
        setSelectedPhoneNumber("");
        props.handleClose();
    }

    const handleSelectedPhoneNumber = (phoneNumber: string) => {
        setSelectedPhoneNumber(phoneNumber);
    }

    const handleChangeNumber = (type: string) => {

        if (number > 99) {
            return;
        }

        switch (type) {
            case "plus":
                setNumber(number + 1);
                break;
            case "minus":
                if (number === 1) {
                    return;
                }
                setNumber(number - 1);
                break;
        }
    }

    const handleChangeInput = (e: any) => {

        //맨 앞에 2자리 이상인데 맨 앞에 0이면 0을 제거
        if (e.target.value.length > 1 && e.target.value[0] === "0") {
            e.target.value = e.target.value.slice(1);
        }

        //99장 이상은 구매 불가
        if (+e.target.value > 99) {
            return;
        }

        setNumber(+e.target.value);
    }

    const submit = async () => {

        try {


            if (!selectedPhoneNumber) {
                alert("전화번호를 선택해주세요");
                return;
            }

            if (!number) {
                alert("수량을 선택해주세요");
                return;
            }

            setLoading(true);

            const data = {
                selectedPhoneNumber: selectedPhoneNumber,
                numbers: number
            }


            const result: any = await postFetch("/dashboard/restaurant/sendmealticketlink", data);


            if (result.message === "success") {

                history.push({
                    pathname: "/mobiledashboard/mealtickettext",
                    state : {
                    selectedPhoneNumber: formatPhoneNumber(selectedPhoneNumber),
                    numbers: number
                    }
                })

            }

            setLoading(false);

        } catch (e) {
        }

    }

    return (
        <IonModal isOpen={props.isOpen}>
            <InnerHeaderForModal handleClose={handleClose}></InnerHeaderForModal>

            <div className={styles.modalBody}>
                <div className={styles.modalTitle}>
                    몇 장을 구매할까요?
                </div>

                <div className={styles.ticketClickDivContainer}>
                    <div className={styles.ticketClickDiv}>
                        <div className={styles.ticketClickDivTitle}>
                            정기식권
                        </div>
                        <div className={styles.ticketClickDivContent}>
                            <button className={styles.ticketClickDivCircle}
                                onClick={() => handleChangeNumber("minus")}
                            >
                                <Minus
                                    width={"100%"}
                                />
                            </button>
                            <div className={styles.ticketClickDivNumber}>
                                <input type="number" value={number}
                                    className={styles.input}
                                    onChange={handleChangeInput}
                                />
                            </div>
                            <button className={styles.ticketClickDivCircle}
                                onClick={() => handleChangeNumber("plus")}
                            >
                                <Plus
                                    width={"100%"}
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <div className={styles.ticketTextDiv}>
                    <div className={styles.ticketTextDivText}>
                        총 수량
                    </div>
                    <div className={styles.ticketTextDivNumber}>
                        {number}장
                    </div>
                </div>

                <div className={styles.ticketTextDiv}>
                    <div className={styles.ticketTextDivText}>
                        식권 금액
                    </div>
                    <div className={`${styles.ticketTextDivNumber} ${styles.ticketTextDivNumberBold}`}>
                        {(number * oneMealTicketPrice).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                    </div>
                </div>


                <div className={styles.phoneTitle}>
                    결제링크 전송 번호 선택
                </div>

                <div>
                    {RadioPositionEnd(props.phoneNumbers, handleSelectedPhoneNumber)}
                </div>

                <div className={styles.justHeight}>

                </div>
            </div>

            <BottomButton text="결제링크 전송" submit={submit} />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            />
        </IonModal>
    )

}

export default MealTicketModal;
