import { MenuItem, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { tableUseStyle } from '../../etc/tableusestyle';
import StyledDataGrid from '../styles/tablestyles';
import { DataGridPremium, GRID_ROOT_GROUP_ID, GridEventListener, GridFilterModel, GridGroupingColDefOverride, GridGroupNode, GridRowModel, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { CalendarTodayTwoTone, SubdirectoryArrowRight, SwapVertSharp } from '@mui/icons-material';
import styles from "../styles/table.module.css";
import { createImportSpecifier } from 'typescript';
import { Button, Dropdown, IconButton, Input, Menu, MenuButton, Modal, Option, Radio, RadioGroup, Sheet, Select } from '@mui/joy';
import { render } from 'react-dom';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'
import e from 'express';

const isGroupExpandedByDefault = (node: GridGroupNode) => {
    return (
        node.groupingField === "projectName"
        ||
        node.groupingField === "sectionName"
    )
}

const TodoTable: React.FC<any> = (props: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [alignment, setAlignment] = useState<string | null>("total");
    const [selectedContent, setSelectedContent] = useState<any>("");
    const [selectedProjectId, setSelectedProjectId] = useState<any>(null);
    const [selectedSectionId, setSelectedSectionId] = useState<any>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
    const [radioSelectedSectionId, setRadioSelectedSectionId] = useState<any>(null);
    const [dropDownOpenRow, setDropDownOpenRow] = useState<any>(null);
    const [columns, setColumns] = useState<any>([
        { field: "projectName", headerName: "프로젝트", width: 150, filterable: true },
        { field: "sectionName", headerName: "섹션", width: 100 },
        {
            field: "content", headerName: "내용", width: 1100, editable: true, renderCell: (params: any) => {

                var isGroupRow = false;

                if (params && params.id) {
                    isGroupRow = params.id.includes("auto");
                }

                return (
                    <div className={styles.eachContent} style={{ paddingLeft: params.row.isSubTask ? "24px" : "0px", display: "flex", alignItems: "center" }}>
                        <div className={styles.contentDescription}>
                            {params.row.isSubTask ? <SubdirectoryArrowRight sx={{ fontSize: "16px" }} /> : ""}
                            {params.value}
                        </div>
                        {
                            (!isGroupRow && !params.row.isSubTask)
                                ?
                                <div className={`${styles.hoverIcon}`}
                                    onClick={() => {
                                        setSelectedProjectId(params.row.projectId);
                                        setSelectedContent(params.row.content);
                                        setSelectedSectionId(params.row.sectionId);
                                        setSelectedTaskId(params.row.id);
                                        handleOpenModal();
                                    }}
                                >
                                    <SwapVertSharp style={{ color: "#6d6e6f" }} />
                                </div>
                                :
                                null
                        }

                    </div>
                )
            }
        },
        {
            field: "due", headerName: "기한", width: 70, 
            editable: true,
            renderCell: (params: any) => {

                const makeDataFormat = (date : Date) => {
                    //{date: '2026-02-14', string: '19시00분 2026년2월14일', lang: 'ko', is_recurring: false, datetime: '2026-02-14T19:00:00'}
                    const result = {
                        due_date : date.toISOString().split("T")[0],
                        due_string : `${date.getHours()}시 ${date.getMinutes()}분 ${date.getFullYear()}년${date.getMonth() + 1}월${date.getDate()}일`,
                        due_datetime : date.toISOString()
                    }

                    console.log(result);

                    return result;
                }

                var isGroupRow = false;

                if (params && params.id) {
                    isGroupRow = params.id.includes("auto");
                }

                if(isGroupRow) return null;


                if (!params.value) {
                    return (
                        // <div style={{ display: "flex", alignItems: "center"}}>
                        //     <CalendarTodayTwoTone style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                        // </div>
                        <div>
                            <input type="date" style={{ width: "24px", 
                            backgroundColor: "transparent",
                            border: "none",
                            color: "transparent",
                            fontSize: "24px",
                             }} 
                             onChange={(e) => {
                                console.log(e.target.value);
                                apiRef.current.updateRows([{ id: params.id, due : {date : e.target.value} }]);
                                const data = makeDataFormat(new Date(e.target.value));
                                updateTask(params.id, data);
                             }}
                             />
                        </div>
                    )
                } else {

                    const date = new Date(params.value.date);
                    const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <span style={{
                                width : "60px"
                            }}>
                                {dateString}
                            </span>
                            <input type="date" style={{
                                width: "24px",
                                backgroundColor: "transparent",
                                border: "none",
                                color: "transparent",
                                fontSize: "24px",
                                paddingTop: "4px"
                            }}
                            onChange={(e) => {
                                console.log(e.target.value);
                                apiRef.current.updateRows([{ id: params.id, due : {date : e.target.value} }]);
                                const data = makeDataFormat(new Date(e.target.value));
                                updateTask(params.id, data);
                             }}
                            />

                        </div>
                    )
                }
            }
        },
        { field: "status", headerName: "상태", width: 100 },
        { field: "comments", headerName: "댓글", width: 50 },
        { field: "creatorName", headerName: "작성자", width: 80 },
        { field: "priority", headerName: "우선순위", width: 120, renderCell : (params : any) => {

            var isGroupRow = false;

            if (params && params.id) {
                isGroupRow = params.id.includes("auto");
            }

            if (isGroupRow) return null;

            const returnButtonName = (value: number) => {

                var buttonName = "";

                switch (+params.value) {
                    case 1:
                        buttonName = "낮음";
                        break;
                    case 2:
                        buttonName = "중간";
                        break;
                    case 3:
                        buttonName = "높음";
                        break;
                    case 4:
                        buttonName = "매우높음";
                        break;
                    default:
                        buttonName = "낮음";
                        break;
                }

                return buttonName;
            }

            const buttonName = returnButtonName(params.value);

            
            return (
               <div style={{
                width : "100%",
               }}>
                    <Select defaultValue={`${params.value}`} onChange={(e: any, newValue: any) => {
                        console.log(newValue);
                        apiRef.current.updateRows([{ id: params.id, priority : +newValue }]);
                        updateTask(params.id, {priority : +newValue});
                     }}
                    size='sm'   
                    //"danger", "neutral", "primary", "success", "warning"
                    color={params.value === 1 ? "neutral" : params.value === 2 ? "primary" : params.value === 3 ? "warning" : "danger"}
                    variant='soft'
                    >
                        <Option value="1"
                        color="neutral"
                        >낮음</Option>
                        <Option value="2"
                        color="primary"
                        >증긴</Option>
                        <Option value="3"
                        color="warning"
                        >높음</Option>
                        <Option value="4"
                        color="danger"
                        >매우 높음</Option>
                    </Select>
               </div>
            )

        } },
        {
            field : "created_at", headerName : "생성일", width : 80, renderCell : (params : any) => {

                if(!params.value) return null;

                const date = new Date(params.value);
                const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                return (
                    <div>
                        {dateString}
                    </div>
                )

            }
        }

    ]);
    const [rows, setRows] = useState<any>([]);
    const apiRef = useGridApiRef();
    const classes = tableUseStyle();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "projectName",
                operator: "contains",
                value: ""
            },
        ],
    });



    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) return;

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((item: any) => {

            if (item.field === "projectName") {
                item.value = newAlignment;
            }

            if (newAlignment === "total") {
                item.value = "";
            }

        })

        setFilterModel({ ...newFilterModel });

        setAlignment(newAlignment);

    };

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                model: [
                    "projectName",
                    "sectionName"
                ]
            }
        }
    })

    useEffect(() => {

        if (!props.projects) return;

        if (props.projects.length === 0) return;

        makeRows(props.projects);

    }, [props.projects]);

    const makeRows = (data: any) => {

        const newRows: any = [];

        data.forEach((project: any) => {

            const projectId = project.id;
            const projectName = project.name;

            project.sections.forEach((section: any) => {

                const sectionName = section.name;
                const sectionId = section.id;

                section.tasks.forEach((task: any) => {

                    task.projectId = projectId;
                    task.sectionId = sectionId;
                    task.projectName = projectName;
                    task.sectionName = sectionName;

                    const row = task;

                    newRows.push(row);

                    task.subTasks.forEach((subTask: any) => {

                        subTask.projectName = projectName;
                        subTask.sectionName = sectionName;
                        subTask.isSubTask = true;

                        newRows.push(subTask);

                    });

                });

            });

        })

        setRows([...newRows]);

    }

    const handleCloseModal = () => {
        setRadioSelectedSectionId(null);
        setModalOpen(false);
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    }

    const updateATask = async (taskId: any, body: any) => {

        const apiKey = props.apiKey;

        if (!apiKey) return;

        const url = `https://api.todoist.com/rest/v2/tasks/${taskId}`;

        try {

            const result = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${apiKey}`
                },
                body: JSON.stringify(body)
            });

            const data = await result.json();

            console.log(data);

        } catch (e) {
            console.log(e);
        }

    }

    async function moveTask(taskId: any, sectionId: any) {
        try {

            props.taskSectionChanged(taskId, sectionId);
            // 브라우저 환경 지원 시
            const uuidValue = crypto.randomUUID(); // 범용 고유 식별자 생성

            const response = await fetch('https://api.todoist.com/sync/v9/sync', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${props.apiKey}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    commands: JSON.stringify([
                        {
                            type: 'item_move',
                            uuid: uuidValue,   // 위에서 생성한 uuid 값
                            args: {
                                id: taskId,      // 이동할 Task의 ID
                                section_id: sectionId // 새 부모(Parent)의 ID
                            },
                        },
                    ]),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('moveTask result:', data);

            const newRows = rows;



        } catch (error) {

            props.letsUpdate();
            console.error('moveTask error:', error);

        }
    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const taskId = newRow.id;

            const data : any = {};

            data[field] = value;

            console.log(data);

            updateTask(taskId, data);

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {

        alert(error.message);

    }, []);

    const updateTask = async (taskId: any, data: any) => {

        fetch(`https://api.todoist.com/rest/v2/tasks/${taskId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.apiKey}`
            },
            body: JSON.stringify(data)
        }).then((res) => {
            console.log(res);
        }).catch((e) => {
            console.log(e);
            props.letsUpdate();
        });

    }

    return (
        <div>
            <div style={{ marginBottom: "24px", marginTop: "24px" }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                >
                    {
                        props.projects.map((project: any, index: number) => {
                            return (
                                <>
                                    {
                                        index === 0 ? <ToggleButton key={99999} value="total" style={{ borderRadius: "0px" }}>
                                            전체TODO
                                        </ToggleButton> : null
                                    }
                                    <ToggleButton key={project.id} value={project.name} style={{ borderRadius: "0px" }}>
                                        {project.name}
                                    </ToggleButton>
                                </>

                            )
                        })
                    }
                </ToggleButtonGroup>
            </div>

            <div className={classes.root} style={{ width: '1800px', backgroundColor: "white" }}>
                <StyledDataGrid
                autoHeight
                    rows={rows}
                    columns={columns}
                    density='compact'
                    loading={props.loading}
                    hideFooter
                    disableRowSelectionOnClick
                    apiRef={apiRef}
                    initialState={initialState}
                    isGroupExpandedByDefault={isGroupExpandedByDefault}
                    rowHeight={54}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => setFilterModel(model)}
                    getCellClassName={(params) => {
                        if (params.row.isSubTask) {
                            if (params.field === "content") {
                                return "subTask";
                            }
                        }
                        return "";
                    }}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />
            </div>

            

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: "transparent",
                            backdropFilter: "blur(0px)"
                        }
                    }
                }}
            >
                <Sheet
                    sx={{
                        bgcolor: 'white',      // Sheet 배경색 (예: 흰색)
                        color: 'black',        // 글자색
                        p: 2,                  // 내부 패딩
                        borderRadius: '8px',   // 모서리 둥글게
                        boxShadow: 'lg',       // 그림자,
                        width: "350px"
                    }}
                >
                    <div style={{
                        fontFamily: "Apple_SB",
                        fontSize: "18px",
                    }}>
                        섹션 간 이동 (
                        {
                            props.projects.map((project: any) => {
                                if (project.id === selectedProjectId) {
                                    return project.name;
                                }
                            })
                        }
                        )
                    </div>
                    <div style={{
                        fontFamily: "Apple_R",
                        fontSize: "16px",
                        marginTop: "16px",
                        color: " #555E68",
                        marginBottom: "16px"
                    }}>
                        {/* 
                        15자리까지만 표현
                        */}
                        {
                            selectedContent
                        }
                    </div>
                    <div style={{
                        marginBottom: "24px"
                    }}>
                        <RadioGroup
                            value={radioSelectedSectionId}
                            onChange={(e) => {
                                setRadioSelectedSectionId(e.target.value);
                            }}
                        >
                            {
                                props.projects.map((project: any) => {

                                    if (project.id !== selectedProjectId) {
                                        return null;
                                    }

                                    const sections = project.sections;

                                    return sections.map((section: any) => {

                                        if (section.id === selectedSectionId) {
                                            return null;
                                        }

                                        if (!section.id) {
                                            return null;
                                        }

                                        if (section.id.includes("without")) {
                                            return null;
                                        }

                                        return (
                                            <Radio value={section.id}
                                                label={
                                                    <span style={{
                                                        fontFamily: "Apple_R",
                                                        fontSize: "14px",
                                                        color: " #555E68"
                                                    }}>
                                                        {section.name}
                                                    </span>
                                                }
                                            />

                                        )
                                    })
                                })
                            }
                        </RadioGroup>
                    </div>
                    <Button
                        fullWidth
                        disabled={radioSelectedSectionId === null}
                        onClick={() => {

                            if (!radioSelectedSectionId) return;
                            moveTask(selectedTaskId, radioSelectedSectionId);
                            handleCloseModal();
                        }}

                    >
                        이동시키기
                    </Button>

                </Sheet>


            </Modal>

            



        </div>
    )

}

export default TodoTable;