import React, { useEffect, useState, useRef } from "react";
import Footer from "../etc/footer";
import styles from "./mobiletotalmenu.module.css";
import { IonAvatar } from "@ionic/react";
import { getAvatarSrc } from "../../etc/functions";
import { getFetch } from "../../fetch/fetch";
import { useHistory } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

import { ReactComponent as LogoutSvg } from "../../svg/totalmenu/arrow-up-left-from-circle-light.svg";
import { ReactComponent as QuitSvg } from "../../svg/totalmenu/user-xmark-light.svg";

import { ReactComponent as AttendanceSvg } from "../../svg/totalmenu/calendar-clock-thin.svg";
import { ReactComponent as NotificationSvg } from "../../svg/totalmenu/calendar-exclamation-thin.svg";
import { ReactComponent as OpinionSvg } from "../../svg/totalmenu/child-reaching-thin.svg";
import { ReactComponent as OfflineSvg } from "../../svg/totalmenu/circle-question-thin.svg";
import { ReactComponent as OnlineSvg } from "../../svg/totalmenu/cloud-question-thin.svg";
import { ReactComponent as ChatSvg } from "../../svg/totalmenu/comment-thin.svg";
import { ReactComponent as ConsultSvg } from "../../svg/totalmenu/file-pen-thin.svg";
import { ReactComponent as EditSvg } from "../../svg/totalmenu/file-signature-thin.svg";
import { ReactComponent as RegularSvg } from "../../svg/totalmenu/file-spreadsheet-thin.svg";
import { ReactComponent as Home } from "../../svg/totalmenu/house-thin.svg";
import { ReactComponent as RestaurantSvg } from "../../svg/totalmenu/pie-thin.svg";
import { ReactComponent as TextSvg } from "../../svg/totalmenu/input-text-thin.svg";
import { ReactComponent as Chevron } from "../../svg/chevron-right-light.svg";
import { ReactComponent as LogoSvg } from '../../svg/totalmenu/graylogo.svg';
import { ReactComponent as WifipermitSvg } from "../../svg/wifipermit.svg";
import { ReactComponent as BroadcastSvg } from "../../svg/totalmenu/megaphone-thin.svg";

import Input from '@mui/joy/Input';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { CssVarsProvider } from "@mui/joy";
import Ripples from 'react-ripples';
import { set } from "lodash";





const MobileTotalMenu: React.FC<any> = (props) => {

    const footerRef = useRef<any>(null);
    const [howMuch, setHowMuch] = useState<number | null>();
    const [avatarSrc, setAvatarSrc] = useState("");
    const history = useHistory();
    const [bodyHeight, setBodyHeight] = useState(0);
    const [open, setOpen] = React.useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);

    const [isRippling, setIsRippling] = useState(new Array(20).fill(false));
    const [rippleCoords, setRippleCoords] = useState({ x: -1, y: -1 });

    const menuItems = [
        { path: "/mobiledashboard", title: "홈", SvgComponent: Home },
        { path: "/mobiledashboard/edit", title: "사유제출", SvgComponent: EditSvg },
        { path: "/mobiledashboard/envelope", title: "메세지", SvgComponent: ChatSvg },
        { path: "/mobiledashboard/notificationList", title: "공지사항", SvgComponent: NotificationSvg },
        { path: "/mobiledashboard/attendance", title: "출석기록", SvgComponent: AttendanceSvg },
        { path: "/mobiledashboard/regular", title: "정기일정", SvgComponent: RegularSvg },
        { path: "/mobiledashboard/consultreport", title: "상담일지", SvgComponent: ConsultSvg },
        { path: "/mobiledashboard/offlinequestion", title: "오프라인 질의응답", SvgComponent: OfflineSvg, additionalClass: (props.user && props.user.value === "parent") ? styles.parentNoSee : "" },
        { path: "/mobiledashboard/onlinequestion", title: "온라인 질의응답", SvgComponent: OnlineSvg, additionalClass: (props.user && props.user.value === "parent") ? styles.parentNoSee : "" },
        { path: "/mobiledashboard/restaurant", title: "도시락 신청", SvgComponent: RestaurantSvg },
        { path: "/mobiledashboard/wifipermit", title : "방화벽 해제 신청", SvgComponent : WifipermitSvg},
        { path: "/mobiledashboard/opinion", title: "의견 보내기", SvgComponent: OpinionSvg, additionalClass: (props.user && props.user.value === "parent") ? styles.parentNoSee : "" },
        { path: "/mobiledashboard/wordtest", title: "단어 테스트", SvgComponent: TextSvg, additionalClass: (props.user && props.user.value === "parent") ? styles.parentNoSee : "" },
        { path : "/mobiledashboard/broadcast", title : "방송하기", SvgComponent : BroadcastSvg, additionalClass : (props.user && (props.user.value !== "teacher" || props.user.academy !== "sunbae" )) ? styles.noSee : "" },
        { title: "로그아웃", SvgComponent: LogoutSvg, action: () => logOut() },
        { title: "계정 탈퇴", SvgComponent: QuitSvg, action: () => setOpen(true) }
    ];




    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!footerRef || !footerRef.current) {
                return;
            }


            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const footerHeight = footerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - footerHeight

            
            
            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [footerRef]);

    const getAvatar = async () => {
        try {
            const avatarSrc: any = await getAvatarSrc();
            
            
            setAvatarSrc(avatarSrc);

        } catch (e) {
            
        }

    }

    const getDate = async () => {
        try {
            const result: any = await getFetch("/dashboard/profile/howmuch");
            if (result.message === "success") {
                
                const today = new Date().getTime();

                const gap = (+today) - (+result.createdAt);
                const howMuchDay = Math.floor(gap / 86400000);
                setHowMuch(howMuchDay);
            }
        } catch (e) {
            
        }
    }

    //아바타 그림 소스랑 가입한 날짜 가져오기
    useEffect(() => {
        if (props.user) {
            getAvatar();
            getDate();
        }
    }, [props.user]);


    //로그아웃 시키는 기능-----------------------------------------------------
    const logOut = async () => {
        //일렉트론 이라면 저장된 토큰 삭제-----------------------------------------------------
        if (window.electron) {
            const result = await window.electron.sendMessageApi.setToken("logOut");
            
        }

        if (Capacitor.isNativePlatform()) {
            await Preferences.set({
                key: "token",
                value: "logOut"
            })
        }

        // ipcRenderer.send("signOut");

        //-----------------------------------------------------------------------

        //쿠키 삭제 위해 서버로 fetch 보냄-----------------------------------------
        fetch("https://peetsunbae.com/login/logout", {
            method: "GET",
            credentials: "include"
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    history.push({
                        pathname: "/",
                        state: { from: "dashboard" }
                    });
                })
        }).catch((err) => {
            
        })
        //--------------------------------------------------------------------------

        //-------------------------------------------------------------------
    }
    //-----------------------------------------------------------------------


    const deleteAccount = async () => {

        if (!password) {
            return;
        }

        setLoading(false);

        var token: any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/login/deleteAccount", {
            method: "delete",
            headers: {
                "Authorization": token,
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                password
            })
        }).then((response) => {
            response.json()
                .then((result: any) => {
                    

                    if (result.message === "success") {
                        alert("계정이 삭제 되었습니다.");
                        logOut();
                    }

                    if (result.message === "noEmail") {
                        alert("잘못된 이메일 주소입니다.");
                    }

                    if (result.message === "incorrectPassword") {
                        alert("잘못된 비밀번호입니다");
                    }

                }).catch((e) => {
                    
                })
        })

    }

    const handleMouseDown = (e: React.TouchEvent<HTMLDivElement>, index: number) => {

        var count = 0;

        isRippling.forEach((item) => {
            if (item) {
                count++;
            }
        });

        if(count > 0){
            return;
        }

        const newIsRippling = [...isRippling];
        newIsRippling[index] = true;

        
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.targetTouches[0].clientX - rect.left;
        const y = e.targetTouches[0].clientY - rect.top;
        setRippleCoords({ x, y });
        setIsRippling([...newIsRippling]);

        // setTimeout(() => {

        //     const voidIsRippling = new Array(20).fill(false);
        //     setIsRippling([...voidIsRippling]);
        //     setRippleCoords({ x: -1, y: -1 });

        // }, 150);

    }

    // useEffect(() => {

    //     var count = 0;

    //     isRippling.forEach((item) => {
    //         if (item) {
    //             count++;
    //         }
    //     });

    //     if(count === 0){
    //         setRippleCoords({ x: -1, y: -1 });
    //     }

    // }, [isRippling]);

    // useEffect(() => {

    //     if(rippleCoords.x !== -1 && rippleCoords.y !== -1){
    //     }


    // }, [rippleCoords]);

    //     useEffect(() => {
    //     if (!isRippling) {
    //         setRippleCoords({ x: -1, y: -1 });
    //     }
    // }, [isRippling]);

    // useEffect(() => {

    //     if (rippleCoords.x !== -1 && rippleCoords.y !== -1) {
    //         
    //         setIsRippling(true);
    //         setTimeout(() => {
    //             setIsRippling(false);
    //         }, 1000);
    //     } else {
    //         setIsRippling(false);
    //     }

    // }, [rippleCoords]);






    return (
        <div style={{ paddingTop: "env(safe-area-inset-top)" }} >

            <div style={{ height: `${bodyHeight}px`, overflowX: "scroll", backgroundColor: "#f5f5f5" }}>
                <div className={styles.white}>
                    <div className={styles.header}>
                        <div className={styles.avatarDiv}>
                            <IonAvatar className={styles.avatar}>
                                <img alt="Silhouette of a person's head" src={avatarSrc ? avatarSrc : "https://ionicframework.com/docs/img/demos/avatar.svg"} className={styles.avatarImg} />
                            </IonAvatar>
                        </div>
                        <div className={styles.nameDiv}>
                            <div className={styles.name}>
                                {props.user && props.user.name}
                            </div>
                            <div className={styles.howMuchDay}>
                                가입한지 {howMuch && howMuch}일
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.bodyWrapper}>
                    <div className={styles.body}>
                        <div className={styles.bodyTitle}>
                            메뉴
                        </div>
                        {
                            menuItems.map(({ title, SvgComponent, path, action, additionalClass }, index) => (
                                <div className={styles.eachMenuWrapper}>
                                    <div className={`${styles.eachMenu} ${additionalClass}`} onClick={(e: any) => {
                                        if (action) {
                                            action();
                                        } else {
                                            history.push(path);
                                        }
                                    }}
                                        // onTouchStart={(e) => { handleMouseDown(e, index); }}
                                    >
                                        <div className={styles.eachMenuTitleDiv}>
                                            <div className={styles.svgDiv}>
                                                <SvgComponent className={styles.svg} />
                                            </div>
                                            <div className={styles.titleDiv}>
                                                {title}
                                            </div>
                                        </div>
                                        <div className={styles.chevronDiv}>
                                            <Chevron className={styles.chevron} fill="#9b9b9b" />
                                        </div>
                                    </div>
                                    {
                                        isRippling[index] ?
                                            <span className={styles.ripple} style={{
                                                left: `${rippleCoords.x}px`,
                                                top: `${rippleCoords.y}px`,
                                            }} />
                                            :
                                            ""
                                    }
                                </div>
                            ))
                        }


                    </div>

                </div>


                <div className={styles.authorWrapper}>
                    <div className={styles.authorDiv}>
                        <div className={styles.logoDiv}>
                            <LogoSvg className={styles.logo} />
                        </div>
                        <div className={styles.author}>
                            Developed By 고용범. (3.0.5)
                        </div>
                    </div>
                </div>


            </div>



            <Footer unreadMessage={props.unreadMessage} footerRef={footerRef} unread={props.unread} location="total" />

            <CssVarsProvider>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog
                        aria-labelledby="basic-modal-dialog-title"
                        aria-describedby="basic-modal-dialog-description"
                        sx={{
                            maxWidth: 500,
                            borderRadius: 'md',
                            p: 3,
                            boxShadow: 'lg',
                        }}
                    >
                        <Typography
                            id="basic-modal-dialog-title"
                            component="h2"
                            level="inherit"
                            fontSize="1.25em"
                            mb="0.25em"
                            fontFamily="Apple_SB"
                        >
                            계정 탈퇴
                        </Typography>
                        <Typography
                            id="basic-modal-dialog-description"
                            mt={0.5}
                            mb={2}
                            textColor="text.tertiary"
                            fontFamily="Apple_R"
                        >
                            탈퇴 시 모든 데이터가 삭제됩니다.
                        </Typography>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                setOpen(false);
                            }}
                        >
                            <Stack spacing={2}>
                                <Input type="password" placeholder="비밀번호 확인" required value={password} onChange={(e) => { setPassword(e.target.value); }} />
                                <Button type="submit" onClick={deleteAccount}>
                                    <span style={{ fontFamily: "Apple_SB" }}>
                                        제출
                                    </span>
                                </Button>
                            </Stack>
                        </form>
                    </ModalDialog>
                </Modal>

            </CssVarsProvider>

        </div>
    );
}

export default MobileTotalMenu;