import styles from "../dashboard/mobiledashboard.module.css";

import { ReactComponent as Abc } from "../../svg/ABC.svg";
import { ReactComponent as Consult } from "../../svg/consult.svg";
import { ReactComponent as Meal } from "../../svg/meal.svg";
import { ReactComponent as Month } from "../../svg/month.svg";
import { ReactComponent as Offline } from "../../svg/offline.svg";
import { ReactComponent as Online } from "../../svg/online.svg";
import { ReactComponent as Opinion } from "../../svg/opinion.svg";
import { ReactComponent as Regular } from "../../svg/regular.svg";
import { ReactComponent as AlarmClock } from "../../svg/alarm-clock-regular.svg";
import { ReactComponent as CheckListMenu } from "../../svg/checklistmenu.svg";
import { ReactComponent as Wifi } from "../../svg/wifi-permit3.svg";
import { ReactComponent as Meal2 } from "../../svg/meal2.svg";


interface Menu {
    id: number;
    english: string;
    svg: () => JSX.Element;
    korean: string;
    /** Which academies are allowed. Use ['all'] if no academy restriction. */
    academy: string[];
    /** The path you want to link to. Using array but usually will have a single item. */
    location: string[];
    url: string;
    /** If true, hide this menu when user.value === 'parent'. */
    onlyNonParent?: boolean;
}

const menuList: Menu[] = [
    {
        id: 1,
        english: "attendance",
        svg: () => <Month className={styles.monthIcon} />,
        korean: "출석&벌점",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/attendance",
    },
    {
        id: 2,
        english: "regular",
        svg: () => <Regular className={styles.regularIcon} />,
        korean: "정기일정",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/regular",
    },
    {
        id: 3,
        english: "consultreport",
        svg: () => <Consult className={styles.consultIcon} />,
        korean: "상담일지",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/consultreport",
    },
    {
        id: 7,
        english: "opinion",
        svg: () => <Opinion className={styles.opinionIcon} />,
        korean: "의견 보내기",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/opinion",
        onlyNonParent: true,
    },
    {
        id: 4,
        english: "offlinequestion",
        svg: () => <Offline className={styles.offlineIcon} />,
        korean: "Off 질의응답",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/offlinequestion",
        onlyNonParent: true, //(!props.user || props.user.value !== "parent")
    },
    {
        id: 5,
        english: "onlinequestion",
        svg: () => <Online className={styles.onlineIcon} />,
        korean: "On 질의응답",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/onlinequestion",
        onlyNonParent: true,
    },   
    // {
    //     id :12,
    //     english: "meal2",
    //     svg: () => <Meal2 className={styles.mealIcon} />,
    //     korean: "정기식",
    //     academy: ["sunbae"],
    //     location: ["total"],
    //     url: "/mobiledashboard/meal2",
    // },
    {
        id: 6,
        english: "restaurant",
        svg: () => <Meal className={styles.mealIcon} />,
        korean: "도시락 신청",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/restaurant",
    },
    {
        id: 9,
        english: "checklist",
        svg: () => <CheckListMenu className={styles.checkIcon} />,
        korean: "스케쥴노트 검사",
        // user && (user.academy === "mom") && not parent
        academy: ["mom"],
        location: ["total"],
        url: "/mobiledashboard/checklist",
        onlyNonParent: true,
    },
    {
        id: 11,
        english: "wifipermit",
        svg: () => <Wifi className={styles.opinionIcon} />,
        korean: "방화벽 해제",
        academy: ["total"],
        location: ["total"],
        url: "/mobiledashboard/wifipermit",
        onlyNonParent: true,
    }
    // {
    //     id: 8,
    //     english: "wordtest",
    //     svg: () => <Abc className={styles.abcIcon} />,
    //     korean: "영단어 시험",
    //     // user && (user.academy === "sunbae" || "molip" || "bundangsunbae") && not parent
    //     academy: ["sunbae", "molip", "bundangsunbae"],
    //     location: ["강남점", "대치점", "대치3층", "대치6층"],
    //     url: "/mobiledashboard/wordtest",
    //     onlyNonParent: true,
    // },
];

export default menuList;