import React, { useEffect, useState, useRef } from "react";
import styles from "./innerheader.module.css";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { ReactComponent as Coin } from "../../../src/svg/coin.svg";
import { ReactComponent as Ticket } from "../../../src/svg/ticket.svg";
import { ReactComponent as RightChevron} from "../../../src/svg/chevron-right-light.svg";


const InnerHeader: React.FC<any> = (props) => {

    const [platform, setPlatform] = useState("");
    const history = useHistory();

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);

    const handleClick = (e: any) => {
        if (props.home) {
            history.push("/mobiledashboard");
        } else {
            history.goBack();
        }
    }

    function formatNumberWithCommas(num : number | undefined) {

        if (!num) {
            return 0;
        }

        // 숫자를 문자열로 변환
        const str = num.toString();
        // 정규 표현식을 이용해 뒤에서부터 3자리마다 콤마 추가
        return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

    return (
        <div style={{ 
            paddingTop: "env(safe-area-inset-top)" 
        }}>
            <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={props.headerRef}>
                <div className={styles.backBtnDiv}
                    onClick={(e: any) => { handleClick(e) }}
                >
                    <ArrowLeftLight
                        className={styles.arrowLeft}
                    />
                </div>

                <div className={styles.titleText}>
                    {props.title}
                    {/* &nbsp;<span className={styles.imageNumber}>{data && data.length}</span> */}
                </div>

                {
                    (!props.handleClickSubmitBtn && !props.isRestaurantSubmitPage) &&
                    <div className={styles.empty}>
                    </div>
                }

                {
                    props.handleClickSubmitBtn &&
                    <div className={`${styles.submit}`} onClick={props.handleClickSubmitBtn}>
                        {props.submitBtn}
                    </div>
                }

                {
                    (props.isRestaurantSubmitPage) &&
                    <div className={`${styles.restaurantSubmit}`} onClick={props.handleClickRestaurantSubmit}>
                        <div className={styles.wrapper}>
                            {
                                props.isCoin ? <Coin className={styles.coin} /> : <Ticket className={styles.coin} />
                            }
                            <div className={`${styles.wrapperText} ${props.isCoin ? styles.coinText : styles.ticketText}`}>
                                {props.isCoin ? formatNumberWithCommas(props.chargedMoney) : props.chargedMealTicket}{props.isCoin ? "" : "장"}
                            </div>
                            <div className={styles.wrapperText2}>
                                <RightChevron className={styles.rightChevron} />
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}

export default InnerHeader;