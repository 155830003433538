import React, { useEffect, useState, useRef } from "react";
import { Route, HashRouter as Router, Link, Switch, RouteComponentProps } from "react-router-dom";

import styles from "./mobilecenter.module.css";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

import MobileOpinionWrite from '../opinion/mobileopinionwrite';

import MobileEdit from "../edit/mobileedit";
import MobileEditWrite from "../edit/mobileeditwrite";

import MobileDashboard from "../dashboard/mobiledashboard";
import MobileOpinionMain from '../opinion/mobileopinionmain';
import MobileMyOpinionDetail from '../opinion/mobilemyopiniondetail';
import MobileChat from '../chat/mobilechat';

import mobileSocketControl from "../socket/mobilesocketcontrol";
import { IonApp } from "@ionic/react";
import { Socket } from "socket.io-client";
import MobileChatRoom from "../chat/mobilechatroom";

import nativePushRegistartion from "../../service/pushnotification";
import app from "../../service/firebaseinit";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { useHistory, useLocation } from "react-router-dom";
import { useIonAlert } from "@ionic/react";
import MobileRestaurant from "../restaurant/mobilerestaurant";
import MobileSuccess from "../success/mobilesuccess";
import MobileRechargetText from "../restaurant/mobilerechargetext";
import MobileProfilePicture from "../profile/mobileprofilepicture";
import MobileTotal from "../total/mobiletotal";
import StudyTimeRanking from "../dashboard/studytimeranking";
import MobileOnlineQuestion from "../onlinequestion/mobileonlinequestion";
import MobileOnlineQuestionWrite from "../onlinequestion/mobileonlinequestionwrite";
import MobileOfflineQuestion from "../offlinequestion/mobileofflinequestion";
import MobileOfflineQuestionWrite from "../offlinequestion/mobileofflinequestionwrite";
import NotificationList from "../notification/notificationlist";
import NotificationDetail from "../../dashboard/components/controls/notificationdetail";
import AttendanceMobileReal from "../attendance/mobileattendance";
import MobileNotificationDetailForDashboard from "../dashboard/mobilenotificationdetailfordashboard";
import MobileConsultReport from "../consultreport/mobileconsultreport";
import MobileRegular from "../regular/mobileregular";

import { useMediaQuery } from "react-responsive";

import { CapacitorUpdater } from "@capgo/capacitor-updater";

import { checkMobileMainUpdate } from "../../etc/functions";
import MobileTotalMenu from "../totalmenu/mobiletotalmenu";

import { App, AppState, BackButtonListenerEvent } from "@capacitor/app";
import MobileTimeTable from "../timetable/mobiletimetable";
import MobileWordtest from "../wordtest/mobilewordtest";
import MobileChatRoomForFamily from "../chat/mobilechatroomforfamily";
import MobileUpdate from "../update/mobileupdate";
import UpdateModal from "../components/updatemodal";
import MobileWifiPermit from "../wifipermit/mobilewifipermit";
import MobileCheck from "../check/mobilecheck";
import MobileCheckWrite from "../check/mobilecheckwrite";
import MobileBroadcast from "../broadcast/mobilebroadcast";
import { returnNeedRegularMealLocation } from "../data/datas";
import MobileMealTicketText from "../restaurant/mobilemealtickettext";





interface centerProps extends RouteComponentProps {
    socket: Socket;
    currentScreen: string;
}

interface user {
    name: string;
    value: "student" | "teacher" | "parent" | "staff";
    id: number;
    studentName: string | undefined | null;
    studentId: number | undefined | null;
    academy?: string;
    location?: string;
}

const MobileCenter: React.FC<any> = (centerProps) => {
    const [user, setUser] = useState<user | null>(null);
    const [count, setCount] = useState(0);
    const [messageUpdate, setMessageUpdate] = useState(0);
    const [familyMessageUpdate, setFamilyMessageUpdate] = useState({number : 0, isUpdateNeeded : true});
    const [chargeUpdate, setChargeUpdate] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const [presentAlert] = useIonAlert();

    const [open, setOpen] = useState(false);

    const isLandscape = useMediaQuery({
        orientation: "landscape"
    });
    const [isOnlyCenter, setIsOnlyCenter] = useState(false);
    const [updateUrl, setUpdateUrl] = useState("");
    const [isNeedRegularMeal, setIsNeedRegularMeal] = useState(false);


    const backButtonHandler = (e: BackButtonListenerEvent) => {
        
        //history.goBack()
    }


    //안드로이드 일때 백버튼 작용하기
    useEffect(() => {

        if (Capacitor.getPlatform() === "android") {
            App.addListener("backButton", backButtonHandler);

            return () => {
                App.removeAllListeners();
            }
        }

    }, []);


    const handleStateChange = (state: AppState) => {
        
        
        if (state.isActive) {
            setTimeout(() => {
                unreadMessage();
            }, 3000);
        }
    }


    //스마트폰 백그라운드에서 포어그라운드로 올 때 한번 더 공지사항 새로운거 채팅 새로운거 확인해주기
    useEffect(() => {


        App.addListener("appStateChange", handleStateChange);


        return () => {
            App.removeAllListeners();
        }

    }, []);


    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            
            checkMobileMainUpdate(presentAlert, handleOpen, setUrl);
        }
    }, []);

    useEffect(() => {

        if (isLandscape && Capacitor.isNativePlatform()) {
            setIsOnlyCenter(true);
        } else {
            setIsOnlyCenter(false);
        }

    }, [isLandscape]);

    useEffect(() => {

        if (Capacitor.isNativePlatform()) {
            CapacitorUpdater.notifyAppReady();
            CapacitorUpdater.list();
        }

    }, []);


    //처음 dashboard 진입 시 토큰 가지고 있는지랑 가지고 있다면 토큰 정보 받기-------
    useEffect(() => {
        start();
    }, []);
    //-----------------------------------------------------------------------


    const start = async () => {
        var token: any = "";
        //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }
        //------------------------------------------------------------------

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }



        fetch("https://peetsunbae.com/dashboard/home/start", {
            method: "GET",
            credentials: "include",
            headers: { 'Authorization': token }
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    if (result.message === "LOGIN") {
                        setUser({
                            name: result.name,
                            value: result.value,
                            id: result.id,
                            studentName: result.studentName,
                            studentId: result.studentId,
                            academy: result.academy,
                            location: result.location
                        });

                        const needRegularLocations = returnNeedRegularMealLocation();

                        if(needRegularLocations && needRegularLocations.length > 0){
                            if(needRegularLocations.includes(result.location)){
                                setIsNeedRegularMeal(true);
                            }
                        }

                    }
                    if (result.message === "NOT_LOGIN") {
                        centerProps.history.push("/");
                    }
                })
        }).catch(error => {
            
        })
    }

    //push 등록----------------------------------------------------------------------------

    useEffect(() => {
        //web인 상태에서 푸시 메세지
        if (!Capacitor.isNativePlatform()) {
            

            const messaging = getMessaging(app);

            getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then(async (currentToken) => {
                if (currentToken) {
                    
                    

                    const { value } = await Preferences.get({ key: "token" });
                    
                    const userIdToken = "";


                    fetch("https://peetsunbae.com/push/registration", {
                        method: "post",
                        headers: {
                            "content-type": "application/json",
                            "Authorization": userIdToken
                        },
                        credentials: "include",
                        body: JSON.stringify({
                            token: currentToken
                        })
                    }).then((response: any) => {
                        response.json()
                            .then((result: any) => {
                                
                            })
                    })

                    onMessage(messaging, (payload) => {
                        
                        
                    })

                } else {
                    
                }
            }).catch((err) => {
                
            })

        }
    }, [])

    useEffect(() => {
        nativePushRegistartion(history, presentAlert);
    }, [])

    //-------------------------------------------------------------------------------

    //소켓이 자기 방 알려주는 곳-----------------------------------------------------------------
    useEffect(() => {
        if (user && centerProps.socket) {
            centerProps.socket.emit("myRoom", user.id);
            
            if (user.value === "teacher" || user.value === "staff") {
                
                centerProps.socket.emit("teacherRoom");
            }

        }

    }, [user, centerProps.socket]);

    //서버 껏다 켰을때 소켓이 방 다시 알려주는 기능-----------------------------
    useEffect(() => {
        if (!user) {
            return;
        }

        const sendMyRoom = () => {
            if (user) {
                centerProps.socket.emit("myRoom", user.id);
                
                if (user.value === "teacher" || user.value === "staff") {
                    
                    centerProps.socket.emit("teacherRoom");
                }
            }
        }

        centerProps.socket.on("connect", () => {
            sendMyRoom();
        });

    }, [user]);

    //---------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------


    //chat 관련 함수들-----------------------------------------------------------------------------------------

    //답장 안한 message 갯수 물어보는 기능
    const unreadMessage = async () => {
        var token: any = "";
        //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }
        //------------------------------------------------------------------

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/number", {
            method: "GET",
            credentials: "include",
            headers: { 'Authorization': token }
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    
                    var newCount = result.data;
                    const familyUnreadNumber = result.familyUnreadNumber;
                    if(familyUnreadNumber){
                        newCount += familyUnreadNumber;
                    }

                    setCount(newCount);
                })
        }).catch(error => {
            
        })
    }

    useEffect(() => {
        
        unreadMessage();
    }, [location.pathname]);


    useEffect(() => {

        if (!centerProps.socket) {
            return;
        }

        centerProps.socket.on("newChatMessage", () => {
            

            // new window.Notification("새로운 메세지가 왔습니다.", {body : `메세지를 확인해주세요.`});

            unreadMessage();
            setMessageUpdate(Math.random());
        })

        return function cleanup() { centerProps.socket.off("newChatMessage"); }

    }, [centerProps.socket]);

    useEffect(() => {

        if (!user) {
            return;
        }

        if (!centerProps.socket) {
            return;
        }

        if (user.value === "teacher" || user.value === "staff") {

            centerProps.socket.on("studentReadMessage", () => {
                

                // new window.Notification("새로운 메세지가 왔습니다.", {body : `메세지를 확인해주세요.`});

                unreadMessage();
                setMessageUpdate(Math.random());
            })

            return function cleanup() { centerProps.socket.off("studentReadMessage"); }

        }

    }, [user, centerProps.socket]);

    //---------------------------------------------------------------------------------------------------------

    useEffect(() => {

        if(!centerProps.socket){
            return;
        }

        centerProps.socket.on("newFamilyMessage", () => {

            

            unreadMessage();
            setMessageUpdate(Math.random());

            const newFamilyMessageUpdate = {
                number : Math.random(),
                isUpdateNeeded : true
            }

            setFamilyMessageUpdate({...newFamilyMessageUpdate});

        })

        centerProps.socket.on("familyReadMessage", () => {

            

            unreadMessage();
            setMessageUpdate(Math.random());

            const newFamilyMessageUpdate = {
                number : Math.random(),
                isUpdateNeeded : false
            }

            setFamilyMessageUpdate({...newFamilyMessageUpdate});

        })

        return function cleanup() { centerProps.socket.off("familyReadMessage"); centerProps.socket.off("newFamilyMessage"); }

    }, [centerProps.socket]);

    const handleOpen = (boolean : boolean) => {
        setOpen(boolean);
    }

    const setUrl = (url : string) => {
        setUpdateUrl(url);
    }

    return (
            <Router>
                <Switch>
                    <Route exact path="/mobiledashboard" render={(props) => <MobileDashboard user={user} socket={centerProps.socket} unreadMessage={count} unread={unreadMessage} {...props} />}></Route>
                    <Route exact path="/mobiledashboard/opinion" render={(props) => <MobileOpinionMain {...props}></MobileOpinionMain>}></Route>
                    <Route exact path="/mobiledashboard/opinion/write" component={MobileOpinionWrite}></Route>
                    <Route exact path="/mobiledashboard/opinion/detail" component={MobileMyOpinionDetail}></Route>
                    <Route exact path="/mobiledashboard/edit" render={(props) => <MobileEdit unreadMessage={count} unread={unreadMessage} />} ></Route>
                    {/* <Route exact path="/mobiledashboard/edit/write" component={MobileEditWrite}></Route> */}
                    <Route exact path="/mobiledashboard/envelope" render={(props) => <MobileChat user={user} unreadMessage={count} unread={unreadMessage} messageUpdate={messageUpdate} familyMessageUpdate={familyMessageUpdate} socket={centerProps.socket} {...props} />}></Route>
                    <Route exact path="/mobiledashboard/envelope/room" render={(props) => <MobileChatRoom user={user} socket={centerProps.socket} unreadMessage={unreadMessage} messageUpdate={messageUpdate} {...props} />}></Route>
                    <Route exact path="/mobiledashboard/envelope/familyroom" render={(props) => <MobileChatRoomForFamily user={user} socket={centerProps.socket} unreadMessage={unreadMessage} messageUpdate={messageUpdate} familyMessageUpdate={familyMessageUpdate} {...props} />}></Route>
                    <Route exact path="/mobiledashboard/restaurant" render={(props) => <MobileRestaurant user={user} socket={centerProps.socket} isNeedRegularMeal={isNeedRegularMeal} />} />
                    <Route exact path="/mobiledashboard/rechargetext" render={(props) => <MobileRechargetText user={user} />} />
                    <Route exact path="/mobiledashboard/mealtickettext" render={(props) => <MobileMealTicketText user={user} /> } />
                    <Route exact path="/mobiledashboard/success" render={(props) => <MobileSuccess user={user} />} />
                    <Route exact path="/mobiledashboard/profilepicture" render={(props) => <MobileProfilePicture user={user} />} />
                    <Route exact path="/mobiledashboard/total" render={(props) => <MobileTotal user={user} unreadMessage={count} />} />
                    <Route exact path="/mobiledashboard/studytimeranking" render={(props) => <StudyTimeRanking user={user} />} />
                    <Route exact path="/mobiledashboard/onlinequestion" render={(props) => <MobileOnlineQuestion user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/onlinequestionwrite" render={(props) => <MobileOnlineQuestionWrite user={user} />} />
                    <Route exact path="/mobiledashboard/offlinequestion" render={(props) => <MobileOfflineQuestion user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/offlinequestionwrite" render={(props) => <MobileOfflineQuestionWrite user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/checkwrite" render={(props) => <MobileCheckWrite user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/notificationlist" render={(props) => <NotificationList user={user} socket={centerProps.socket} unreadMessage={count} unread={unreadMessage} />} />
                    <Route exact path="/mobiledashboard/notificationdetail" render={(props) => <NotificationDetail user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/attendance" render={(props) => <AttendanceMobileReal user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/mobilenotificationdetailfordashboard" render={(props) => <MobileNotificationDetailForDashboard user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/consultreport" render={(props) => <MobileConsultReport user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/regular" render={(props) => <MobileRegular user={user} />} />
                    <Route exact path="/mobiledashboard/totalmenu" render={(props) => <MobileTotalMenu user={user} unreadMessage={count} unread={unreadMessage} />} />
                    <Route exact path="/mobiledashboard/timetable" render={(props) => <MobileTimeTable user={user} />} />
                    <Route exact path="/mobiledashboard/wordtest" render={(props) => <MobileWordtest user={user} />} />
                    <Route exact path="/mobiledashboard/update" render={(props) => <MobileUpdate user={user} />} />
                    <Route exact path="/mobiledashboard/wifipermit" render={(props) => <MobileWifiPermit user={user} />} />
                    <Route exact path="/mobiledashboard/checklist" render={(props) => <MobileCheck user={user} socket={centerProps.socket} />} />
                    <Route exact path="/mobiledashboard/broadcast" render={(props) => <MobileBroadcast user={user} socket={centerProps.socket} />} />
                </Switch>
                <UpdateModal open={open} updateUrl={updateUrl} onClose={() => {}} /> 
            </Router>
    );
}

export default MobileCenter;