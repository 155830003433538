import React, { useEffect, useState } from "react";
import styles from "../styles/regularmealstatus.module.css";
import { getFetch } from "../../../../../fetch/fetch";
import { koreanLocationToEnglish } from "../../../../../data/locationFuntion";


const howManyDays = 30;

const RegularMealTable: React.FC<any> = (props) => {

    const [rows1, setRows1] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        if(props.rows1){

            setRows1(props.rows1);
            return;
        }

        start();

    }, [props.rows1]);

    const start = async () => {

        const newDateArray = [];
        const startDate = new Date();
        startDate.setDate(startDate.getDate() + 6);

        for (var i = 0; i < howManyDays * 2; i++) {
            const startDateString = startDate.getFullYear() * 10000 + (startDate.getMonth() + 1) * 100 + startDate.getDate();
            const whenType = i % 2 === 0 ? "lunch" : "dinner";
            const oneRow : any = {};
            oneRow.whenType = whenType;
            oneRow.id = startDateString;
            newDateArray.push(oneRow);
            if(i % 2 === 1)
            startDate.setDate(startDate.getDate() - 1);
        }

        console.log(newDateArray);

        const data = await getTotalData();

        console.log(data);

        const rows : any[] = [];

        newDateArray.forEach((date : any) => {

            const oneRow : any = {};
            oneRow.id = date.id;
            oneRow.whenType = date.whenType;
            oneRow.date = dateNumberToString(date.id, date.whenType);

            data.forEach((element : any) => {

                if(element.isManualSubtract){
                    return;
                }

                const englishLocation = koreanLocationToEnglish(element.location);

                if(!englishLocation){
                    return;
                }
                
                if(!oneRow[englishLocation]){
                    oneRow[englishLocation] = [];
                }

                if(element.targetDateString === date.id && element.whenType === date.whenType){
                    oneRow[englishLocation].push(element);
                }
            })
            
            rows.push(oneRow);
        });

        console.log(rows);

        setRows1(rows);

    }

    const dateNumberToString = (dateNumber : number, whenType : string) => {

        //20210701 -> 7/1 (월요일)

        const year = Math.floor(dateNumber / 10000);
        const month = Math.floor((dateNumber % 10000) / 100);
        const date = dateNumber % 100;

        const day = new Date(year, month - 1, date).getDay();
        const dayString = ["일", "월", "화", "수", "목", "금", "토"][day];

        return `${month}/${date} (${dayString}) - ${whenType === "lunch" ? "점심" : "저녁"}`;
    }

    const getTotalData = async () => {

        try{

            setLoading(true);

            const result : any = await getFetch(`/dashboard/restaurant/regularmeal/total?howManyDays=${howManyDays}`);

            if(result.message === "success"){

                const data = result.data;

                data.forEach((element : any) => {
                    const targetDate = new Date(element.targetDate);
                    const targetDateString = targetDate.getFullYear() * 10000 + (targetDate.getMonth() + 1) * 100 + targetDate.getDate();
                
                    element.targetDateString = targetDateString;
                })

                return data;

            }

            setLoading(false);

        }catch(e){
            console.warn(e);
            setLoading(false);
        }

    }

    

    return (
        <div>
            <div className={`${styles.container} ${props.rows1 ? styles.noMargin : ""}`}>
                <table className={styles.table}>
                    <colgroup>
                        <col style={{width: "150px"}} />
                        {/* 나머지 컬럼은 전부 80px로 */}
                        {
                            Array.from({length: 6}, () => {
                                return <col style={{width: "80px"}} />
                            })
                        }
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan={7}>
                                정기식 신청 현황
                            </th>
                        </tr>
                        <tr>
                            <th rowSpan={2}>
                                날짜
                            </th>
                            <th rowSpan={2}>
                                강남점
                            </th>
                            <th colSpan={4}>
                                대치점
                            </th>
                            <th rowSpan={2}>
                                총 합계
                            </th>
                        </tr>
                        {/* 대치점은 하단에 2층, 3층 6층 */}
                        <tr>
                            <th>
                                2층
                            </th>
                            <th>
                                3층
                            </th>
                            <th>
                                6층
                            </th>
                            <th>
                                대치점
                            </th>
                        </tr>
                    </thead>
                    {
                        props.loading && rows1.map((row : any) => {

                            if(props.filter){
                                if(props.filterId !== row.id){
                                    return null;
                                }
                            }

                            return (
                                <tr key={row.id + row.whenType}
                                className={styles.oneRow}
                                >
                                    <td style={{
                                        fontFamily : "Apple_SB"
                                    }}>
                                        -
                                    </td>
                                    <td style={{
                                        color : "blue"
                                    }}>
                                        -
                                    </td>
                                    <td>
                                        -
                                    </td>
                                    <td>
                                        -
                                    </td>
                                    <td>
                                        -
                                    </td>
                                    <td style={{
                                        color : "blue"
                                    }}>
                                        -
                                    </td>
                                    <td style={{
                                        color : "blue",
                                        fontFamily : "Apple_B",
                                        fontSize : "17px"
                                        }}>
                                        -
                                    </td>
                                </tr>
                            )
                        })
                    }
                    
                    {
                        !props.loading && rows1.map((row : any) => {

                            if(props.filter){
                                if(props.filterId !== row.id){
                                    return null;
                                }
                            }

                            return (
                                <tr key={row.id + row.whenType}
                                className={styles.oneRow}
                                >
                                    <td style={{
                                        fontFamily : "Apple_SB"
                                    }}>
                                        {row.date}
                                    </td>
                                    <td style={{
                                        color : "blue"
                                    }}>
                                        {row["gangnam"] ? row["gangnam"].length : 0}
                                    </td>
                                    <td>
                                        {row["daechi"] ? row["daechi"].length : 0}
                                    </td>
                                    <td>
                                        {row["daechi2"] ? row["daechi2"].length : 0}
                                    </td>
                                    <td>
                                        {row["daechi3"] ? row["daechi3"].length : 0}
                                    </td>
                                    <td style={{
                                        color : "blue"
                                    }}>
                                        {
                                            (row["daechi"] ? row["daechi"].length : 0)
                                            +
                                            (row["daechi2"] ? row["daechi2"].length : 0)
                                            +
                                            (row["daechi3"] ? row["daechi3"].length : 0)
                                        }
                                    </td>
                                    <td style={{
                                        color : "blue",
                                        fontFamily : "Apple_B",
                                        fontSize : "17px"
                                        }}>
                                        {
                                            (row["gangnam"] ? row["gangnam"].length : 0)
                                            +
                                            (row["daechi"] ? row["daechi"].length : 0)
                                            +
                                            (row["daechi2"] ? row["daechi2"].length : 0)
                                            +
                                            (row["daechi3"] ? row["daechi3"].length : 0)
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </div>
    )
}

export default RegularMealTable;